import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import ax2012 from "../../../assets/images/casestudies/ax2012.png";
import SQL from "../../../assets/images/casestudies/sql2012.jpg";
import managementreport from "../../../assets/images/casestudies/management-report.png";
import manufacturingbanner from "../../../assets/images/banner/manufacturing-advances-textiles-for-automotive-sector-banner.jpg";
import CustomBreadcrumb from "../.././BreadCrumbs/CustomebreadCrumb";
import Automotivesector from "../../../assets/images/banner/automotive-sector.jpg";
import { Helmet } from "react-helmet";

function manufacturingadvancestextilesforautomotivesector2() {
  const breadcrumbItems = [
    { label: "Home", link: "/" },
    { label: "Case Studies", link: "/case-studies" },
    { label: "Manufacturing – Advances Textiles for Automotive Sector" },
  ];
  return (
    <>
    <Helmet>
        <title>
        Manufacturing – Advances Textiles for Automotive Sector | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Customer is a Japanese multinational firm that specialises in industrial goods based on organic synthetic chemistry, polymer chemistry, and biochemistry technologies."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/manufacturing-advances-textiles-for-automotive-sector-2" />
      </Helmet>
      <div className="insurance">
        <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>
                    Manufacturing – Advances Textiles for Automotive Sector
                  </h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img
                    src={Automotivesector}
                    loading="lazy"
                    alt="Manufacturing Advances Textiles for Automotive Sector
"
                    title="Manufacturing  Advances Textiles for Automotive Sector"
                  />
                  
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <CustomBreadcrumb items={breadcrumbItems} />
          <div className="casestudy">
            <Row>
              <Col xs={12} md={4}>
                <div>
                  <img src={iconclient} alt="icon-client" title="icon-client" />
                </div>
                <h3>Brief about the Client</h3>
              </Col>
              <Col xs={12} md={8}>
                <div>
                  <p>
                    Customer is a Japanese multinational firm that specialises
                    in industrial goods based on organic synthetic chemistry,
                    polymer chemistry, and biochemistry technologies.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="graybg-casestudy">
            <Row>
              <Col xs={12} md={4}>
                <div>
                  <img
                    src={iconoverview}
                    alt="icon-overview"
                    title="icon-overview"
                  />
                </div>
                <h3>Business Overview</h3>
              </Col>
              <Col xs={12} md={8}>
                <div>
                  <p>
                    Customer has chosen to adopt Microsoft Dynamics AX 2012 at a
                    global level, with us providing Development and Maintenance
                    services for their Mexico unit. According to Mexican
                    government regulations, every sales invoice must be verified
                    by the government before being sent to the client, and the
                    invoice must include accurate tax calculation details in
                    areas where the consumer has had previous problems.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="casestudy">
            <Row>
              <Col xs={12} md={4}>
                <div>
                  <img
                    src={iconchallenges}
                    alt="icon-challenges"
                    title="icon-challenges"
                  />
                </div>
                <h3>Business Challenges</h3>
              </Col>
              <Col xs={12} md={8}>
                <div>
                  <p>
                    Every sales invoice must be approved by the government
                    before being issued to the client, and the invoice must
                    include proper tax calculation details in areas where the
                    consumer has had previous problems, according to Mexican
                    government regulations.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="graybg-casestudy">
            <Row>
              <Col xs={12} md={4}>
                <p>
                  <img
                    src={iconresults}
                    alt="icon-results"
                    title="icon-results"
                  />
                </p>
                <h3>Outcome and Benefits</h3>
              </Col>
              <Col xs={12} md={8}>
                <div>
                  <p>
                    "Check Invoice" is a new feature that allows you to
                    double-check your invoice before submitting it to the
                    government. For inventory in production, a new functionality
                    for generating Palletizing numbers was developed. The rebate
                    procedure is automated. Standardization of HO-aligned
                    business processes.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container>
          <div className="engaged-col">
            <Row>
              <Col md={12}>
                <h3>Tools and Technologies</h3>
              </Col>
              <Col xs={6} md={3} className="d-inline-flex">
                <div className="engaged-col-bg">
                  <div>
                    <img src={ax2012} alt="ax2012" title="ax2012" />
                  </div>
                  <p>MS Dynamics AX 2012 R3</p>
                </div>
              </Col>
              <Col xs={6} md={3} className="d-inline-flex">
                <div className="engaged-col-bg">
                  <div>
                    <img src={SQL} alt="SQL" title="SQL" />
                  </div>
                  <p>SQL SERVER 2012 R2</p>
                </div>
              </Col>
              <Col xs={6} md={3} className="d-inline-flex">
                <div className="engaged-col-bg">
                  <div>
                    <img
                      src={managementreport}
                      alt="management-report"
                      title="management-report"
                    />
                  </div>
                  <p>Management Reporter</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default manufacturingadvancestextilesforautomotivesector2;
