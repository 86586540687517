import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import WCFServices from "../../../assets/images/casestudies/wcf-services.png";
import sharepoint from "../../../assets/images/casestudies/sharepoint.jpg";
import php from "../../../assets/images/casestudies/php.jpg";
import SQL2012 from "../../../assets/images/casestudies/sql2012.jpg";
import unifiedservicesbanner from "../../../assets/images/banner/unified-services-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function unifiedservices() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Unified Services Platform a global ISV' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Unified Services Platform a global ISV | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="The client is a Fortune 500 firm specializing in PC and server software as well as corporate productivity solutions. The client has a strong consulting and global services delivery business that delivers infrastructure, implementation, and bespoke application development services to its clients globally."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/unified-services-platform-a-global-isv" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Unified Services Platform a global ISV</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={unifiedservicesbanner} alt="Unified Services Platform a global ISV" title="Unified Services Platform a global ISV" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The client is a Fortune 500 firm specializing in PC and server software as well as corporate productivity solutions. The client has a strong consulting and global services delivery business that delivers infrastructure, implementation, and bespoke application development services to its clients globally.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Our client envisaged an Industrialized method for offering infrastructure services, deployment, and upgrade services to its clients worldwide as part of a three-year strategic effort. The Unified Services Platform was envisioned as the enabler that would simplify the company's global operations - from sales through delivery. Capabilities would be provided by the Platform.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The business aim challenges are to improve service quality and to reduce costs. Improve win percentages for large and complicated agreements by bringing uniformity to deal estimations across locations. The platform's tough area provides capabilities such as self-service automated deal estimates, sophisticated cross-LOB deal estimations, and workflow driven service delivery operations in a factory model.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The platform delivers increased agility to the sales team in providing complicated deal estimates to their customers, significant savings in the efforts spent by pre-sales SMEs due to the automated estimating engine, deal win probability prediction based on historical data, and much more, industrialised delivery operations that optimised resource costs, insights on deal win trends and factory efficiency via interactive dashboards for KPIs, and global access to the platform and applications across multiple devices.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={WCFServices} alt="WCF-Services" title="WCF-Services"/>
                            </div>
                            <p>	WCF Services </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sharepoint} alt="sharepoint" title="sharepoint"/>
                            </div>
                            <p>SharePoint 2013 Services</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={php} alt="php" title="php"/>
                            </div>
                            <p>	AppFabric Cache Server</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={SQL2012} alt="SQL2012" title="SQL2012"/>
                            </div>
                            <p>SQL Server 2012</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default unifiedservices;
