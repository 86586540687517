import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SYPopup from "../../controls/popup";
import { useState } from "react";
import arrow from "../../assets/images/arrow.png";
import whitearrow from "../../assets/images/arrow-white.png";
import gargi from "../../assets/images/gargi.jpg";
import bhumi from "../../assets/images/bhumi.jpg";
import dilip from "../../assets/images/dilip.jpg";
import siva from "../../assets/images/siva.jpg";
import aditya from "../../assets/images/aditya.jpg";
import jayant from "../../assets/images/jayant.jpg";
import nensi from "../../assets/images/nensi.jpg";
import careerimg from "../../assets/images/career.png";
import "./career.scss";

import contactusbanner from "../../assets/images/banner/career-banner.jpg";
import fastforward from "../../assets/images/animated-icons/fast-forward.gif";
import CustomBreadcrumb from '../BreadCrumbs/CustomebreadCrumb';
import { careers } from './careers'
import { Helmet } from "react-helmet";

function Career() {

  const [GargiStory, setShow] = useState(false);
  const [BhumiStory, setShow3] = useState(false);
  const [DilipStory, setShow4] = useState(false);
  const [SivaStory,  setShow5] = useState(false);
  const [AdityaStory, setShow7] = useState(false);
  const [JayantStory, setShow6] = useState(false);
  const [NensiStory, setShow8] = useState(false);

  const breadcrumbItems = [
    { label: 'Home', link: '/' },
    { label: 'About Synoverge',link:'/our-story' },
    { label: 'Career' },

  ];
  return (
    <Container fluid className="px-0" >
        <Helmet>
        <title>
        Career at Synoverge | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="
          Enjoy Life, Fun, and Career Opportunities at Synoverge, a certified Great Place to Work company that offers a vibrant and friendly work environment."
        />
        <link rel="canonical"href="https://www.synoverge.com/career" />
      </Helmet>
      <div className="career">
        <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Career</h1>
                  {/* <h1>Transforming Data into Intelligence</h1> */}
                  <p>
                    Improve decision making through business insights with our
                    analytics roadmap
                  </p>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img
                    src={contactusbanner}
                    alt="Contactus"
                    title="Contactus"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <div className="module">
          <CustomBreadcrumb items={breadcrumbItems} />
            <Row>
            <h3>Join Our Team</h3>
              {
                careers.map((career,index)=>{
                  return(
                    <Col xs={12} md={6} key={index}>
                      <Link to={`./${career.id}`}>
                        <div className="Job-col">
                          <div className="job-logo">
                            <img src={career.img} alt=".net" title=".net" />
                          </div>
                          <div className="job-position">{career.jobPosition} </div>
                          <div className="job-exp">Exp.: {career.experience} | Positions: {career.positions}</div>                                                
                          <div className="arrow">
                            <Link to={`./${career.id}`}>
                            <img src={arrow} alt="arrow" title="arrow" className="black-arrow" />{" "}
                              <img src={whitearrow} alt="arrow" title="arrow" className="white-arrow" />{" "}
                            </Link>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  )
                })
              }
            </Row>
            <Row>
              <Col xs={12}>
                  <div className="career-details">
                    <div className="career-img">
                        <img src={careerimg} alt="career" />
                    </div>
                      <p>Discover exciting career opportunities and take the first step towards joining our team by applying at  <Link to="mailto:career@synoverge.com">  career@synoverge.com</Link> </p>
                  </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="module-gray" id="EmployeeStories">
          <Container>
            <Row>
              <Col lg={12}>
                <h3 className="mb-4">Employee Stories</h3>
              </Col>
            </Row>
            
            <Row >
            <Col xs={12} md={4}>
                <div className="emp-stories">
                  <img src={gargi} alt="Gargi Mehta" title="Gargi Mehta" />
                  <h4>Gargi Mehta</h4>
                  <p>
                    I am fortunate to be part of Synoverge Family and grateful
                    to this organization for helping me develop my excellence.
                    An organization is a platform that will provide
                  </p>
                  <Link to="#" onClick={() => setShow(true)} className="readmore">Continue Reading <img src={fastforward} className="arrowright" alt="fastforward" title="fastforward" /></Link>
                  <SYPopup show={GargiStory} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Gargi Mehta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        <img
                          src={gargi}
                          loading="lazy"
                          alt="Gargi Mehta"
                          className="d-block mx-auto"                          
                        />
                      </p>
                      <p>#GreatPlaceToWork #SynovergeFamily</p>
                      <p>
                        I am fortunate to be part of Synoverge Family and
                        grateful to this organization for helping me develop my
                        excellence. An organization is a platform that will
                        provide me number of opportunities to showcase my talent
                        and help me develop new skills which will work in
                        providing mutual benefit to the company as well as my
                        interest. I would like to take this opportunity to thank
                        the organization for their immense acknowledgement and
                        investment they do in converting my skills to my
                        strengths.
                      </p>
                    </Modal.Body>
                  </SYPopup>
                </div>
              </Col>
              
              <Col xs={12} md={4}>
                <div className="emp-stories">
                  <img src={dilip} loading="lazy" alt="Dilip Shukal" title="Dilip Shukal" />
                  <h4>Dilip Shukal</h4>
                  <p>
                    I am working with Synoverge since last 10 Years, My overall
                    experience is good. Teammates are supportive, even our
                    managers are also very supportive. You can develop
                  </p>
                  <Link to="#" onClick={() => setShow4(true)} className="readmore">Continue Reading <img src={fastforward} className="arrowright" alt="fastforward" title="fastforward" /></Link>
                  <SYPopup show={DilipStory} onHide={() => setShow4(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Dilip Shukal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        <img
                          src={dilip}
                          loading="lazy"
                          alt="Dilip Shukal"
                          className="d-block mx-auto"
                        />
                      </p>
                      <p>
                      I am working with Synoverge since last 10 Years, My overall experience is good. Teammates are supportive, even our managers are also very supportive. You can develop your technical skills here. Has lot to learn from here. Has efficient management and work culture is also good. People are cordial. Salary and benefits are according to Industry standard and that is motivating factor. The office environment is lively yet professional, and probably, it is a much good thing to have. The company consistently asks for feedback and makes changes based on the suggestions of employees. Great office, great benefits, salary is always on time. In last, I am enjoying my working with this organization.
                      </p>
                    </Modal.Body>
                  </SYPopup>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="emp-stories">
                  <img src={siva} loading="lazy" alt="Siva kumar" title="Siva kumar" />
                  <h4>Siva kumar</h4>
                  <p>
                    One of the best IT company in country. Synoverge follows the
                    ISO standards in Projects, which provides best quality
                    output to clients.
                  </p>
                  <Link to="#" onClick={() => setShow5(true)} className="readmore">Continue Reading <img src={fastforward} className="arrowright" alt="fastforward" title="fastforward" /></Link>
                  <SYPopup show={SivaStory} onHide={() => setShow5(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Siva kumar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        <img
                          src={siva}
                          loading="lazy"
                          alt="Siva kumar"
                          className="d-block mx-auto"
                        />
                      </p>
                      <p>
                      One of the best IT company in country. Synoverge follows the ISO standards in Projects, which provides best quality output to clients. The work life balance and customer supports are balanced with flexible timings for employees. Synoverge management are easily approachable to all employees for any professional concerns and employees are treated as an asset to the company irrespective of their designations. Synoverge helps in upgrading the employees professional talents and knowledge. In addition to all above, Synoverge also arranges cultural and sports activities regularly for employees which creates a enjoyable working environment.
                      </p>
                    </Modal.Body>
                  </SYPopup>
                </div>
              </Col>
            </Row>
            <Row className="pt-4">
            <Col xs={12} md={4}>
                <div className="emp-stories">
                  <img src={bhumi} loading="lazy" alt="Bhumi Madhu" title="Bhumi Madhu" />
                  <h4>Bhumi Madhu</h4>
                  <p>
                    Almost 7 Years! It’s a long journey. Isn’t it? I’ve seen
                    significant successful growth of our company and personal
                    growth as well. Technology adoption in our company is the
                    great part
                  </p>
                  <Link to="#" onClick={() => setShow3(true)} className="readmore">Continue Reading <img src={fastforward} className="arrowright" alt="fastforward" title="fastforward" /></Link>
                  <SYPopup show={BhumiStory} onHide={() => setShow3(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Bhumi Madhu</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        <img
                          src={bhumi}
                          loading="lazy"
                          alt="Bhumi Madhu"
                          className="d-block mx-auto"
                        />
                      </p>
                      <p>
                      Almost 7 Years! It’s a long journey. Isn’t it? I’ve seen significant successful growth of our company and personal growth as well. Technology adoption in our company is the great part I can say because acceptance and integration of new technologies into an existing system can be a major growth see the good result. I am happy that I am a part of this organization.
                      </p>
                    </Modal.Body>
                  </SYPopup>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="emp-stories">
                  <img src={jayant} loading="lazy" alt="Jayant Shah" title="Jayant Shah" />
                  <h4>Jayant Shah</h4>
                  <p> Working as a Technical Lead in Synoverge Technologies Pvt. Ltd. for 2 years has been an
                            incredible journey and the most challenging yet satisfying phase of my professional career.</p>
                  <Link to="#" onClick={() => setShow6(true)} className="readmore">Continue Reading <img src={fastforward} className="arrowright" alt="fastforward" title="fastforward" /></Link>
                  <SYPopup show={JayantStory} onHide={() => setShow6(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Jayant Shah</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        <img
                          src={jayant}
                          loading="lazy"
                          alt="Jayant Shah"
                          className="d-block mx-auto"
                        />
                      </p>
                      <p> Working as a Technical Lead in Synoverge Technologies Pvt. Ltd. for 2 years has been an
                            incredible journey and the most challenging yet satisfying phase of my professional career.
                            I got opportunities to drive different projects, grooming young talents, technical
                            initiatives, and much more which helped me to grow my technical and leadership skills.</p>
                        <p>The organizational culture at Synoverge Technologies Pvt. Ltd. is something truly special. It
                            fosters an environment of creativity, innovation, and inclusivity, where everyone's ideas
                            and contributions are valued. The emphasis on continuous learning and professional
                            development has allowed me to stay at the forefront of technology. I am fortunate to have a
                            great team to work with at Synoverge.</p>
                        
                    </Modal.Body>
                  </SYPopup>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="emp-stories">
                  <img src={aditya} loading="lazy" alt="Aditya Amin" title="Aditya Amin" />
                  <h4>Aditya Amin</h4>
                  <p>Working as a Software Engineer (Functional Consultant) in the Enterprise department at Synoverge Technologies Pvt. Ltd. for 2 years has been a transformative experience.</p>
                  <Link to="#" onClick={() => setShow7(true)} className="readmore">Continue Reading <img src={fastforward} className="arrowright" alt="fastforward" title="fastforward" /></Link>
                  <SYPopup show={AdityaStory} onHide={() => setShow7(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Aditya Amin</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        <img
                          src={aditya}
                          loading="lazy"
                          alt="Aditya Amin"
                          className="d-block mx-auto"
                        />
                      </p>
                      <p>Working as a Software Engineer (Functional Consultant) in the Enterprise department at Synoverge Technologies Pvt. Ltd. for 2 years has been a transformative experience. Collaborating with clients, delivering tailored solutions, and being part of a dynamic team fueled my professional growth. The access to cutting-edge technologies and the company's commitment to continuous learning made it an exciting and rewarding journey. </p>
                      <p>Moreover, the Enterprise department at Synoverge Technologies Pvt. Ltd. fostered a supportive and collaborative work culture. The team's collective expertise and diverse perspectives created a stimulating environment where knowledge sharing and innovation thrived. I felt valued and supported throughout my time there, with Jayanta, Jainal and Siva offering guidance and encouragement. This sense of camaraderie not only enhanced my productivity but also made the workplace a genuinely enjoyable and fulfilling place to be. </p>
                      
                    </Modal.Body>
                  </SYPopup>
                </div>
              </Col>
            </Row>
            <Row Row className="pt-4">
            <Col xs={12} md={4}>
                <div className="emp-stories">
                  <img src={nensi} loading="lazy" alt="Nensi Kardani" title="Nensi Kardani" />
                  <h4>Nensi Kardani</h4>
                  <p>

Having dedicated 6.5+ years to Synoverge as a Senior Software Engineer in the Mobility department, I can assert that this tenure has been a source of both professional enrichment and personal fulfillment.</p>
                  <Link to="#" onClick={() => setShow8(true)} className="readmore">Continue Reading <img src={fastforward} className="arrowright" alt="fastforward" title="fastforward" /></Link>
                  <SYPopup show={NensiStory} onHide={() => setShow8(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Nensi Kardani</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        <img
                          src={nensi}
                          loading="lazy"
                          alt="Nensi Kardani"
                          className="d-block mx-auto"
                        />
                      </p>
                      <p>Synoverge is not just a workplace; it's a vibrant community of talented professionals who are passionate about pushing the boundaries of technology. As a Senior Software Engineer, I have had the privilege of working on cutting-edge projects in the Mobility domain, contributing to innovative solutions that have a real impact.</p>
                      <p>The Mobility department, in particular, is a hub of creativity and collaboration. The team is not just focused on delivering top-notch solutions but is also driven by a shared passion for staying ahead in the ever-evolving of mobile technologies.</p>
                      <p>I am grateful for the opportunities and experiences Synoverge has provided me over the years. Synoverge Technologies—a place where experience is valued, innovation is celebrated, and every team member is empowered to make a meaningful impact. I look forward to continuing to contribute to the organization's success and being a part of its innovative journey.</p>
                      
                    </Modal.Body>
                  </SYPopup>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Container>
  );
}

export default Career;
