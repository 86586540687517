import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import BC from "../../../assets/images/casestudies/business-central.jpg";
import logisticsanddistributionbanner from "../../../assets/images/banner/logistics-distribution-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import Constructionmanufacturing from "../../../assets/images/banner/construction-manufacturing.jpg";
import { Helmet } from "react-helmet";


function logisticsanddistribution() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Construction and Manufacturing' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Construction and Manufacturing | Synoverge 
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="The company's unwavering dedication to excellence extends across every facet of its construction system, encompassing design, manufacturing, safety/quality management, and building construction."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/construction-and-manufacturing" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Construction and Manufacturing</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={Constructionmanufacturing} loading="lazy" alt="Construction and Manufacturing" title="Construction and Manufacturing" />
                </div>                
              </Col>
            </Row>
          </Container>
      </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The company's unwavering dedication to excellence extends across every facet of its construction system, encompassing design, manufacturing, safety/quality management, and building construction. Our client takes immense pride in adopting and leveraging best practices in these critical areas. For them, the commitment to quality is not just a procedural step; it's a way of life, a guiding code that underscores their core values. The real strength of our client lies in its people, who embody the principles of quality, making them a formidable force in the construction industry.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Their holistic approach to construction, coupled with an unwavering commitment to quality, firmly establishes our client's business as an industry leader in North America.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Our client faces a significant business challenge concerning its main product, Lumber, which is categorized and priced through various classifications. These classifications serve the purpose of filtering and generating various inventory reports. Precision in rounding is crucial for them as Lumber transactions involve ordering, fulfilment, and reception in pieces. While other units of measure (UOMs) are employed for quoting and operational purposes, they are not utilized in purchase orders or inventory management. The complexity arises from the fact that the ordering unit of measure aligns with the inventory unit of measure, even though Lumber is quoted in Board Feet and Material in Square Feet (SQFT). Both units of measure are converted to pieces, and the price per unit is used for ordering. To streamline and automate this conversion during the "Make Order" process, an enhancement needs to be integrated into BC, posing a key business challenge for our client.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The successful implementation of a cohesive ERP system marks a pivotal achievement for the organization, offering a structured approach to item categorization through attributes and units of measure (UOMs), complemented by robust built-in reporting features. This transformation has led to streamlined business processes, introducing automation for UOM conversions and price calculations that carefully consider both UOM and item attributes. Notably, the ERP system has facilitated efficient employee reimbursement procedures across diverse companies, accommodating various check formats seamlessly. Furthermore, the introduction of workflows for items and jobs has significantly enhanced task management and coordination, promoting a more organized operational environment. The system's impact extends to inventory reconciliation, contributing to smoother month-end closing procedures. Additionally, leveraging data from the General Ledger (GL) and Job Ledger Entries has resulted in improved cost calculation accuracy, further enhancing the organization's overall efficiency and financial management.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={BC} alt="DNAV" title="DNAV"/>
                            </div>
                            <p>Dynamics 365 Business Central</p>
                        </div>
                    </Col>
                    
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default logisticsanddistribution;
