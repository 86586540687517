import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import jenk from "../../../assets/images/casestudies/jenk.png";
import se from "../../../assets/images/casestudies/se.png";
import automationtestingbanner from "../../../assets/images/banner/auto-ancillary-manufacturing-company-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";



function automationtesting() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Automation Testing'},
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Automation Testing | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="The client is a well-known US-based consultancy that provides agricultural and financial advice to farmers. They've been serving farming businesses and requirements for over 40 years and have a strong presence in the Midwest of the United States. They collaborate with farmers to increase output and profit."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/automation-testing" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Automation Testing</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={automationtestingbanner} alt="Automation Testing" title="Automation Testing" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The client is a well-known US-based consultancy that provides agricultural and financial advice to farmers. They've been serving farming businesses and requirements for over 40 years and have a strong presence in the Midwest of the United States. They collaborate with farmers to increase output and profit. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Consultancy that gives horticultural and monetary guidance to farmers. They've been serving cultivating organizations and prerequisites for north of years and have a solid presence in the Midwest of the US. They work together with farmer to build result and benefit.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The quality assurance process for the Client's major core application was fast becoming difficult to manage, with over 5,000 manual test cases, 1,500 of which were regression test cases. We needed to discover a solution to minimise testing durations, increase the number of test cases, and enhance overall system quality without adding extra testing time because the programme was always growing (new features were introduced).</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Approximately 1500 TCs are automated, with more complicated calculations. Increased overall coverage of the tests In regression testing, roughly 10 PDs of work is saved on average.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={jenk} alt="jenk" title="jenk"/>
                            </div>
                            <p>	Selenium </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={se} alt="se" title="se"/>
                            </div>
                            <p>	Jenkins </p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default automationtesting;
