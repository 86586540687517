import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import managementreport from "../../../assets/images/casestudies/management-report.png";
import azure from "../../../assets/images/casestudies/azure.png";
import finance from "../../../assets/images/casestudies/finance.png";
import Retailtrading from "../../../assets/images/banner/retail-trading.jpg";
import { Helmet } from "react-helmet";

function logisticsanddistribution() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Retail & Trading' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Retail & Trading | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Our client is a prominent Limited Liability Company (LLC) with its headquarters situated in California, USA. Renowned for its leadership in the market, the company specializes in providing a diverse range of household items, including packed foods, fashion and beauty products, home décor, and more."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/retail-and-trading" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Retail & Trading</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                <img src={Retailtrading} loading="lazy" alt="Retail & Trading" title="Retail & Trading" />
                </div>                
              </Col>
            </Row>
          </Container>
      </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Our client is a prominent Limited Liability Company (LLC) with its headquarters situated in California, USA. Renowned for its leadership in the market, the company specializes in providing a diverse range of household items, including packed foods, fashion and beauty products, home décor, and more.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>With a customer-centric approach, the client has established itself as a leading provider in the industry, delivering quality products to meet the varied needs of its consumer base. Operating from the heart of the United States, the client exemplifies excellence and innovation in the realm of household essentials.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The customer encountered significant hurdles in the integration of data, transactions, and accounting processes across multiple applications. Managing financial transactions on disparate platforms proved to be time-consuming, involving substantial efforts in data collation and computation, thereby increasing the risk of human errors. The imperative arose to automate processes within the Finance and Operations (F&O) module of Dynamics 365 (D365) to mitigate or eliminate manual interventions. The overarching goal was to consolidate operations onto a single platform, facilitating comprehensive business management and analysis. Additionally, the customer sought a robust financial reporting tool within D365 F&O to enhance the efficiency of generating financial reports.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Implementing an integrated auto financial system, enriched with user-defined controls and permissions, marks a strategic leap for the customer, fostering business expansion. The automated capture of end-to-end transactions through API and file integrations ensures precision, eliminating human errors. Furthermore, the commitment to seamless financial reporting with a sophisticated experience underscores the organization's dedication to enhancing financial intelligence and enabling informed decision-making.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
            <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={finance} alt="finance" title="finance"/>
                            </div>
                            <p>D365 Finance & Operation</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={azure} alt="azure" title="azure"/>
                            </div>
                            <p>Microsoft Azure</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={managementreport} alt="management-report" title="management-report"/>
                            </div>
                            <p>Management Reporter</p>
                        </div>
                    </Col>
                    
                    
                    
                    
                </Row>
            </div>
        </Container>
      </div>
  );
}

export default logisticsanddistribution;
