import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import windowazure from "../../../assets/images/casestudies/windowazure.png";
import bootstrap from "../../../assets/images/casestudies/bootstrap.jpg";
import sqlserver from "../../../assets/images/casestudies/sqlserver.png";
import webapi from "../../../assets/images/casestudies/webapi.png";
import tokbox from "../../../assets/images/casestudies/tokbox.png";
import livechat from "../../../assets/images/casestudies/livechat.png";
import interviewmanagementplatformbanner from "../../../assets/images/banner/interview-management-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";


function interviewmanagementplatform() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Interview Management Platform' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Interview Management Platform | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="The leader in the United States has a unique portal that controls and monitors the technical screening life-cycle in real-time mode for clients, candidates, and experts, including self-assessment, group video enabled interviews, and ATS connection."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/interview-management-platform-2" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Interview Management Platform</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={interviewmanagementplatformbanner} alt="Interview Management Platform" title="Interview Management Platform" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The leader in the United States has a unique portal that controls and monitors the technical screening life-cycle in real-time mode for clients, candidates, and experts, including self-assessment, group video enabled interviews, and ATS connection.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The main focus of this interview management system is on dealing with applicant interviews, records, and so forth. In addition, the system displays all of the candidate lists, reports, and question sets that are available. This will generate a report for future use, detailing each contender's total points as well as any further remarks given by the candidate.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Due to the lack of non-functional needs such as significant logging and error handling, support and troubleshooting are extremely challenging. The code base has grown over time, and the maintenance of the coding standard has been hampered by many upgrades and revisions. Because there is no clear data service application, it is difficult to publish platform data for mobile applications. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Redesign UI/UX and refactor code in .Net, Implement modern architecture and design practice blocks. Implement non-functional design blocks like logging, exception, caching etc., Replace the custom JavaScript template engine with Angular framework, Application Services to allow and expose platform data to Web & Mobile application, Design code structure to enable maximum re-usability in Mobile application development. Auto code analysis setup for continuous monitoring of code quality.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={windowazure} alt="windowazure" title="windowazure"/>
                            </div>
                            <p>Azure Cloud & Services</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={bootstrap} alt="bootstrap" title="bootstrap"/>
                            </div>
                            <p>Angular & Bootstrap 3</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sqlserver} alt="sqlserver" title="sqlserver"/>
                            </div>
                            <p>SQL Server</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={webapi} alt="webapi" title="webapi"/>
                            </div>
                            <p>Web API 2</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={tokbox} alt="tokbox" title="tokbox"/>
                            </div>
                            <p>Tokbox</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={livechat} alt="livechat" title="livechat"/>
                            </div>
                            <p>Live Chat</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default interviewmanagementplatform;
