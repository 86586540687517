import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import testrail from "../../../assets/images/casestudies/testrail.png";
import confluence from "../../../assets/images/casestudies/confluence.png";
import SQL2012 from "../../../assets/images/casestudies/sql2012.jpg";
import tfs from "../../../assets/images/casestudies/tfs.png";
import testcasemanagementbanner from "../../../assets/images/banner/test-case-management-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";



function testcasemanagement() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Test Case Management' },
    
      ];

  return (
      <div className="insurance">
        <Helmet>
        <title>
        Test Case Management | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="The client is a well-known agricultural and financial consulting firm situated in the United States. They have a significant presence in the Mid-West area of the United States and have been supporting farming companies and requirements for over 40 years."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/test-case-management" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Test Case Management</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={testcasemanagementbanner} alt="Test Case Management" title="Test Case Management" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The client is a well-known agricultural and financial consulting firm situated in the United States. They have a significant presence in the Mid-West area of the United States and have been supporting farming companies and requirements for over 40 years.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Most farmers lose money on their farm because their predictions about weather, crop prices on the market, and other factors are virtually always wrong. Farmers hire a consultant to help them reduce their risk of losing money by investing in the future and developing strategies for doing so. They collaborate with farmers to increase productivity and earnings. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The application/product was continually evolving (new features were introduced), hence it was important to manage the product's test cases. For each SDLC phase, a different tool is employed. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>First and importantly, use the most up-to-date technologies for requirement management. We integrated their product inside and are leveraging their capabilities for improved Test Case management. We examined our Master suite on a regular basis to ensure that all test cases were in sync with the production box. We've also pulled our automation test cases into each release so that we can take use of automation for increased regression coverage. </p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={testrail} alt="testrail" title="testrail"/>
                            </div>
                            <p>	TestRail </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={confluence} alt="confluence" title="confluence"/>
                            </div>
                            <p>	Confluence </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={SQL2012} alt="SQL2012" title="SQL2012"/>
                            </div>
                            <p>	Jira </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={tfs} alt="tfs" title="tfs"/>
                            </div>
                            <p>	TFS </p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default testcasemanagement;
