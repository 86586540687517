import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import androidjava from "../../../assets/images/casestudies/androidjava.jpg";
import iosswift from "../../../assets/images/casestudies/iosswift.png";
import php from "../../../assets/images/casestudies/php.jpg";
import angular6 from "../../../assets/images/casestudies/angular6.jpg";
import connectedtocropbanner from "../../../assets/images/banner/connected-to-crop-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function connectedtocrop() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Connected to Crop' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Connected to Crop | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="The client is a well-known US-based consultancy that provides agricultural and financial advice to farmers. They have been serving farming businesses and requirements for over 40 years and have a strong presence in the United States' mid-west sector."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/connected-to-crop" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Connected to Crop</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={connectedtocropbanner} alt="Connected to Crop" title="Connected to Crop" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The client is a well-known US-based consultancy that provides agricultural and financial advice to farmers. They have been serving farming businesses and requirements for over 40 years and have a strong presence in the United States' mid-west sector.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Most farmers lose money on their farm because their predictions about weather, crop prices on the market, and other factors are virtually always wrong. Farmers hire a consultant to help them reduce their risk of losing money by investing in the future and developing strategies for doing so.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Global economic variables such as fluctuating commodity prices and trade issues, as well as the reality that a crop can be damaged by weather, insects, or disease, complicate a farmer's commercial decisions. In addition, there are challenges such as planted crops, cost capture data, planting and sowing data, and production projections and actuals.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>As a result, farmers can see the Account summary, which allows them to keep track of their whole operation's financial health. Before making a final choice on marketing, use this visual tool to assess how it will affect the business. A visual aid for tracking output and % sold in comparison to insurance and hedging positions.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={iosswift} alt="iosswift" title="iosswift"/>
                            </div>
                            <p>iOS-Swift 5.0 </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={androidjava} alt="androidjava" title="androidjava"/>
                            </div>
                            <p>Android-Java </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={php} alt="php" title="php"/>
                            </div>
                            <p>Php native & Code Igniter, Backbone JS, Doctrine, SOLR, </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={angular6} alt="angular6" title="angular6"/>
                            </div>
                            <p>	Beanstalk Queue, Angular</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default connectedtocrop;
