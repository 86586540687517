import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import ZAP from "../../../assets/images/casestudies/zap.png";
import acun from "../../../assets/images/casestudies/acun.png";
import securitytestingbanner from "../../../assets/images/banner/security-testing-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";



function securitytesting() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Security Testing' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Security Testing | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Client is a main worldwide supplier of review and confirmation, counselling, monetary warning, hazard warning, charge, and related services. Audit and assurance, consulting, financial advising, risk advisory, tax, and associated services are the major areas of business for the customer."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/security-testing" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Security Testing</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={securitytestingbanner} alt="Security Testing" title="Security Testing" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Client is a main worldwide supplier of review and confirmation, counselling, monetary warning, hazard warning, charge, and related services.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Audit and assurance, consulting, financial advising, risk advisory, tax, and associated services are the major areas of business for the customer. And they save this data on their role-based internal portal, which contains all sensitive client data (salary, business details, balance sheet, etc.). Because this portal is utilised and accessible from a variety of locations throughout the world, it is necessary to do security tests on it. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The attacker model must be defined (internal or external, enabled rights and privileges), <br /> Goals, source data, scope of work, and testing targets must all be defined, <br/> determining the target environment's breadth, Creating a methodology for testing and Procedures for engagement and communication are being defined. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The penetration tester was able to conduct a complete assessment for security holes in the Customer's network using a combination of automation for deep network scanning and human approaches for vulnerability exploitation.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={ZAP } alt="ZAP " title="ZAP "/>
                            </div>
                            {/* <p>	Selenium </p> */}
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={acun} alt="acun" title="acun"/>
                            </div>
                            <p>	Acunetix </p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default securitytesting;
