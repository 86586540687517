import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import iosswift from "../../../assets/images/casestudies/iosswift.png";
import androidjava from "../../../assets/images/casestudies/androidjava.jpg";
import Net from "../../../assets/images/casestudies/net.png";
import sqlite from "../../../assets/images/casestudies/sqlite.png";
import mortgagevendormanagementbanner from "../../../assets/images/banner/mortgage-vendor-management-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function mortgagevendormanagement() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Mortgage Vendor Management' },
    
      ];

  return (
      <div className="insurance">
        <Helmet>
        <title>
        Mortgage Vendor Management | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Our customer in the United States provides appraisal management and other services in more than 50 nations. They assist their customers in increasing productivity by providing more efficient real estate information solutions."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/mortgage-vendor-management" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Mortgage Vendor Management</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={mortgagevendormanagementbanner} alt="Mortgage Vendor Management" title="Mortgage Vendor Management" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Our customer in the United States provides appraisal management and other services in more than 50 nations. They assist their customers in increasing productivity by providing more efficient real estate information solutions.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>They intended to expand their offering to mobile, which may help with business process automation and workflow customization. They assist their client with expanding usefulness by conveying real estate data arrangements all the more effectively.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Identifying which vendors are essential to their business. Carrying out risk evaluations, they are aware of their vendors and maintaining a record of all papers and data. We tailor our due diligence demands to each vendor's specific needs. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Apps for iOS and Android were created to allow appraisers to easily access engagements. For the following stage, review and change the category-by-category ordering. Schedule an appointment and attach any property-specific papers to the order.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={iosswift} alt="iosswift" title="iosswift"/>
                            </div>
                            <p>iOS- Swift 5.0</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={androidjava} alt="androidjava" title="androidjava"/>
                            </div>
                            <p>Android - Java</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sqlite} alt="sqlite" title="sqlite"/>
                            </div>
                            <p>SQLite</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={Net} alt="net" title="net"/>
                            </div>
                            <p>.Net</p>
                        </div>
                    </Col>
                </Row>
            </div>
            </Container>
      </div>
  );
}

export default mortgagevendormanagement;
