import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import ax from "../../../assets/images/casestudies/ax2012.png";
import SQL2012 from "../../../assets/images/casestudies/sql2012.jpg";
import managementreport from "../../../assets/images/casestudies/management-report.png";
import printermanufacturerbanner from "../../../assets/images/banner/printer-manufacturer-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";




function printermanufacturer() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Printer Manufacturer' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Printer Manufacturer | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Customer is a well-known printer company in Singapore with headquarters in Japan. They had multiple systems in several locations, which posed a significant issue in terms of consolidation and multi-currency reporting."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/printer-manufacturer" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Printer Manufacturer</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={printermanufacturerbanner} alt="Printer Manufacturer" title="Printer Manufacturer" />
                </div>                
              </Col>
            </Row>
          </Container>
      </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Customer is a well-known printer company in Singapore with headquarters in Japan. They  had multiple systems in several locations, which posed a significant issue in terms of consolidation and multi-currency reporting.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Printer manufacturing company desire to develop ERP where all the subsidiary company handled by the headquarters general rules so company can handle easily multi-currency report and other operations as well.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Standardization of Business Processes Aligned with HO Design of Global Templates, COA, and Product Code Structure in AX Streamlining the documentation needed in overseas commerce, as the majority of the transaction is direct delivery in nature, with items being dispatched straight from Japan's headquarters to the customer. Separating the warehouse operations in Singapore and Malaysia to make inventory reconciliation easier.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>HO Consolidation is an integrated financials and procurement solution. Few business operations have been automated, resulting in improved transaction and reporting control. Cost savings as a result of improved logistical efficiency. Reduced reliance on outside consultants. Internal reporting has been improved, allowing HQ to consolidate data more quickly and do better analyses. </p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={ax} alt="ax" title="ax"/>
                            </div>
                            <p>MS Dynamics AX 2012 R3</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={SQL2012} alt="SQL-2012" title="SQL-2012"/>
                            </div>
                            <p>	SQL SERVER 2012 R2</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={managementreport} alt="management-report" title="management-report"/>
                            </div>
                            <p>Management Reporter</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default printermanufacturer;
