import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import androidjava from "../../../assets/images/casestudies/androidjava.jpg";
import angular6 from "../../../assets/images/casestudies/angular6.jpg";
import mysqlserver from "../../../assets/images/casestudies/mysqlserver.png";
import logisticsplatformbanner from "../../../assets/images/banner/logistics-platform-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function logisticsplatform() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Logistics Platform' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Logistics Platform | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="One of our Indian logistics customers wants to operate their transportation company with mobile application and keep real-time track on their vehicles."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/logistics-platform" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Logistics Platform</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={logisticsplatformbanner} alt="Logistics Platform" title="Logistics Platform" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>One of our Indian logistics customers wants to operate their transportation company with mobile application and keep real-time track on their vehicles.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Load transportation is still a difficulty, from choosing the correct carrier to getting the load delivered. Tracking the vehicle might be inconvenient at times, even if it is cost effective. Before implementing the system, a thorough study and high-quality logistics are essential to minimise any needless expenses.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Load transport done presently is as yet having difficulties from tracking down the right carrier till the conveyance of the heap. At some point cost adequacy, following the armada can be bulky. To keep away from any pointless expenses, great coordinated factors, complete examination is needed before execution of the framework.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>We have created a logistics platform ecosystem that connects transporters and load shippers. By enrolling and associating with the platform, all logistics service providers such as transportation service businesses, express cargo service companies, movers and packers, Shippers and Cargo Owners (consignor/ consignee), Warehouse, Packers, Movers, and CHAs can profit.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={androidjava} alt="androidjava" title="androidjava"/>
                            </div>
                            <p>Android-Java </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={angular6} alt="angular6" title="angular6"/>
                            </div>
                            <p>Angular</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={mysqlserver} alt="mysqlserver" title="mysqlserver"/>
                            </div>
                            <p>Management Reporter</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default logisticsplatform;
