import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import BC from "../../../assets/images/casestudies/business-central.jpg";
import SQL from "../../../assets/images/casestudies/sql2012.jpg";
import logisticsanddistributionbanner from "../../../assets/images/banner/logistics-distribution-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";




function logisticsanddistribution() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Logistics and Distribution' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Logistics and Distribution | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="As a prominent courier and logistics enterprise in the Asia Pacific region, the company strategically responded to the implementation of GST in India by incorporating 30 new entities into their existing legacy system, showcasing their proactive approach to navigating regulatory changes and adapting to evolving business landscapes."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/logistics-and-distribution" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Logistics and Distribution</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={logisticsanddistributionbanner} alt="logistics-distribution" title="logistics-distribution" />
                </div>                
              </Col>
            </Row>
          </Container>
      </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>As a prominent courier and logistics enterprise in the Asia Pacific region, the company strategically responded to the implementation of GST in India by incorporating 30 new entities into their existing legacy system, showcasing their proactive approach to navigating regulatory changes and adapting to evolving business landscapes.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>As a distinguished player in the courier and logistics sector, the company strategically responded to the introduction of GST in India by establishing 30 distinct entities within their legacy system. This initiative, however, presented multifaceted challenges. Additionally, the substantial operational transactions arising from the customer's sales, receipts, and tax records mandated a significant allocation of human resources within the Accounts department to manage the increased workload.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The toughest part involved setting up one legal organization in the system with GST and withholding tax. They were grappling with challenges like meeting statutory and audit requirements, integrating with their core operations platform, and ensuring accurate data reconciliation due to manual consolidation.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>They've successfully established a single legal entity in the system, equipped with GST and withholding tax configurations. Thanks to integration with their Operations platform, real-time customer generation and sales transactions seamlessly flow into Business Central. The system is set up for automatic posting across various business scenarios such as sales, purchases, fixed asset posting, banking, and cash. Moreover, they've achieved integrations for GST, TDS, and the bank portal.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={BC} alt="DNAV" title="DNAV"/>
                            </div>
                            <p>Dynamics 365 Business Central</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={SQL} alt="SQL2012" title="SQL2012"/>
                            </div>
                            <p>SQL SERVER 2012 R2</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default logisticsanddistribution;
