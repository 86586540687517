import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import ax2012 from "../../../assets/images/casestudies/ax2012.png";
import ssrreport from "../../../assets/images/casestudies/ssr-report.png";
import wellnessservicesbanner from "../../../assets/images/banner/wellness-services-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function wellnessservices() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Wellness Services' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Wellness Services | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Customer based in the United States is known for providing industry-leading autism exams, cutting-edge sensory processing tests, and exceptional customer service."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/wellness-services" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Wellness Services</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={wellnessservicesbanner} alt="Wellness Services" title="Wellness Services" />
                </div>                
              </Col>
            </Row>
          </Container>
      </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Customer based in the United States is known for providing industry-leading autism exams, cutting-edge sensory processing tests, and exceptional customer service.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The company's main markets are the United States and Canada, and its exams are utilised by helping professionals all over the globe to screen, diagnose, and treat persons in need, particularly children and teenagers with developmental, social, or behavioural issues. Customer has introduced the AX 2012 and CRM 2013 systems and need offshore support and maintenance to keep them running well.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Key procedures are being streamlined to make operations run more smoothly. To improve business operations, critical reports are being developed and improved. Application and Production Support on a Ticket Basis for Transparency</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Offshore Shared Services Centre for cost-effective support, Improved Tax Compliance through system validations, Improved Organization Design to provide reporting flexibility, and Automation of a few activities to improve transaction and reporting control.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={ax2012} alt="ax2012" title="ax2012"/>
                            </div>
                            <p>Microsoft Dynamics AX 2012 R2</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={ssrreport} alt="ssr-report" title="ssr-report"/>
                            </div>
                            <p>	SSRS Reporting</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default wellnessservices;
