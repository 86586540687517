import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import mysqlserver from "../../../assets/images/casestudies/mysqlserver.png";
import jQuery from "../../../assets/images/casestudies/jquery.png";
import javascript  from "../../../assets/images/casestudies/javascript.jpg";
import bootstrap  from "../../../assets/images/casestudies/bootstrap.jpg";
import unifiedservicesplatformbanner from "../../../assets/images/banner/collaborative-extranet-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function unifiedservicesplatform() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Collaborative Extranet Product Suite - IR' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Collaborative Extranet Product Suite - IR | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="The client is a Michigan-based product company that specializes in developing B2B and B2C solutions for collaboration across industrial verticals. Large universities, research institutions, business associations, and government chambers of commerce are among the product's customers."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/collaborative-extranet-product-suite" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Collaborative Extranet Product Suite - IR</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={unifiedservicesplatformbanner} alt="Collaborative Extranet Product Suite - IR" title="Collaborative Extranet Product Suite - IR" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The client is a Michigan-based product company that specializes in developing B2B and B2C solutions for collaboration across industrial verticals. Large universities, research institutions, business associations, and government chambers of commerce are among the product's customers.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>When the customer first launched their company, they focused on collaboration-based applications. The customer had created a platform that allowed institutions to interact on funding and grant opportunities. More customers from various industry verticals signed up as the company progressed, and the same application was reproduced across multiple deployments.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>More customers from various industry verticals signed up as the company progressed, and the same application was reproduced across multiple deployments. They had 6 installations of the same product across different domains catering to the same subject of collaboration at the end of two years. On boarding new clients was becoming costlier. To cut maintenance expenses, it was necessary to combine separate applications.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Analysis of information models across 12 applications, bottom-up approach for architecture revamp, experience-driven UI Framework - Ability to easily create newer experiences, rule-based configurability to accommodate application-specific business scenarios, streamlined version control and elimination of redundancies in codebase, and applications now use shared deployment infrastructure.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={javascript } alt="javascript " title="javascript "/>
                            </div>
                            <p>Java, J2EE</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={jQuery} alt="jQuery" title="jQuery"/>
                            </div>
                            <p>jQuery</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={mysqlserver} alt="mysqlserver" title="mysqlserver"/>
                            </div>
                            <p>	JBOSS, MYSQL, Hibernate</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={bootstrap} alt="bootstrap" title="bootstrap"/>
                            </div>
                            <p>	Bootstrap, SOA</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default unifiedservicesplatform;
