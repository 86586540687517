import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import iosswift from "../../../assets/images/casestudies/iosswift.png";
import healtkit from "../../../assets/images/casestudies/healtkit.png";
import Net from "../../../assets/images/casestudies/net.png";
import sqlite from "../../../assets/images/casestudies/sqlite.png";
import healthandinsurancebanner from "../../../assets/images/banner/health-and-insurance-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function healthandinsurance() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Health and Insurance' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Health and Insurance | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="One of India's major insurance companies wanted to create a mobile app that would advise and assist its clients in obtaining the different advantages of their insurance policy by taking substantial measures toward bettering their health."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/health-and-insurance" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Health and Insurance</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={healthandinsurancebanner} alt="Health and Insurance" title="Health and Insurance" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>One of India's major insurance companies wanted to create a mobile app that would advise and assist its clients in obtaining the different advantages of their insurance policy by taking substantial measures toward bettering their health.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Health insurance company wanted to developed a mobile application which help their customer’s to preventing illnesses reduces healthcare costs significantly, particularly when it comes to tracking one's health on a regular basis and  which can guide and help their customers to avail the various benefits of the insurance policies by taking the significant steps in improving the health.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The main challenge in the application to integrate mobile app with wearable fitness devices and fetch the data from the device and manage into mobile application and maintain and manage health insurance and generate alerts.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The development and integration efforts were minimised by establishing a wearable SDK, which increased new users by 10% in a month. There are a lot of nice reviews in the app store, thus the app's rating has improved. Integration with other services, such as the top online medicine provider and online consultation with a doctor, gives users with additional alternatives from a single location. </p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={iosswift} alt="iosswift" title="iosswift"/>
                            </div>
                            <p>iOS- Swift 5.0</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={healtkit} alt="healtkit" title="healtkit"/>
                            </div>
                            <p>Fitbit, Googlefit, HealthKit, Samsung</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={Net} alt="net" title="net"/>
                            </div>
                            <p>.Net</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sqlite} alt="sqlite" title="sqlite"/>
                            </div>
                            <p>SQLite</p>
                        </div>
                    </Col>
                </Row>
            </div>
            </Container>
      </div>
  );
}

export default healthandinsurance;
