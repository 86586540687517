import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import azure from "../../../assets/images/casestudies/azure.png";
import angular from "../../../assets/images/casestudies/angular6.jpg";
import net from "../../../assets/images/casestudies/net.png";
import sqlserver from "../../../assets/images/casestudies/sqlserver.png";
import screeningbackgroundbannner from "../../../assets/images/banner/screening-background-check-services-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";






function screeningbackgroundcheckservices() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Screening & Background Check Services' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Screening & Background Check Services | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="For Screening & background check services, company in the United States has used a third-party site. The client wants to rebuild the solution so that it can be used to create a single portal with a variety of background check services and ATS interfaces."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/screening-background-check-services" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Screening & Background Check Services</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={screeningbackgroundbannner} alt="Screening & Background Check Services" title="Screening & Background Check Services" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>For Screening & background check services, company in the United States has used a third-party site. The client wants to rebuild the solution so that it can be used to create a single portal with a variety of background check services and ATS interfaces. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Usability and a modern user interface , Remove any external reliance and Easy upgrades with your own solution External screening is simple, and ATS and HRIS applications and services are available. Reduce the time it takes to bring a new service to market. NFRs have a lot of meat on them: security, auditing, logging, validations, and exceptions. All services are available through a single platform.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The external product is required to manage and process the many services that are given. - Ongoing costs, limitations on extending functionality for a specific need/change - unrelated to the client's workflow, deteriorating UX Different portals for clients, researchers, and administrators are producing a lot of challenges with user management synchronization.  The current application's user interface and user experience are not up to date or device friendly.  Turnaround time for any enhancements is lengthy - a major constraint based on the client's requirements, Product Vendor Dependency - highly limited and restricted. There is no single gateway for back office personnel, therefore they must utilize two separate portals to process transcript and background check service orders.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The layout is consistent and device friendly, with a modern UI/UX. Modern architecture and design practice blocks should be implemented. Implement non-functional design elements such as logging, exceptions, and caching, among others. Integrations will be able to use API endpoints exposed by Application Data Services. Setup automatic code analysis for ongoing code quality monitoring.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={azure} alt="azure" title="azure"/>
                            </div>
                            <p>Azure Cloud & Services</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={angular} alt="angular6" title="angular6"/>
                            </div>
                            <p>Bootstrap 3 & Angular</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={net} alt="net" title="net"/>
                            </div>
                            <p>	.Net Core 3.1</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sqlserver} alt="sqlserver" title="sqlserver"/>
                            </div>
                            <p>SQL Server</p>
                        </div>
                    </Col>
                </Row>
            </div>
            </Container>
      </div>
  );
}

export default screeningbackgroundcheckservices;
