import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import androidjava from "../../../assets/images/casestudies/androidjava.jpg";
import iosswift from "../../../assets/images/casestudies/iosswift.png";
import sqlite from "../../../assets/images/casestudies/sqlite.png";
import Net from "../../../assets/images/casestudies/net.png";
import selfvehicleinspectionbanner from "../../../assets/images/banner/self-vehicle-inspection-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function selfvehicleinspection() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Self Vehicle Inspection' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Self Vehicle Inspection | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="One of the India's auto insurance companies wants to create a smartphone application that would assist its customers save time for the renew policy to self-inspect their vehicles."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/self-vehicle-inspection" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Self Vehicle Inspection</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={selfvehicleinspectionbanner} alt="Self Vehicle Inspection" title="Self Vehicle Inspection" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>One of the India's auto insurance companies wants to create a smartphone application that would assist its customers save time for the renew policy to self-inspect their vehicles.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>When a consumer demand for a renew policy for an insurance, the consumer must wait a long time for the survey, which requires the surveyor to come to the customer. Once the survey is completed, then client gets a policy. For this circumstance, the customer desired the cost and time to be as low as possible.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>It is necessary to select robust mobile technologies capable of handling car inspection videos and images for all consumers. Furthermore, the customer's video and photographs contain a few criteria for inspecting the automobile.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>We have offered a hassle-free and rapid turn-around option for clients to renew existing policy through mobile phone from any location. The user no longer has to wait for a surveyor's availability to check the car, which saves time and money.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={iosswift} alt="iosswift" title="iosswift"/>
                            </div>
                            <p>iOS-Swift 5.0 </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={androidjava} alt="androidjava" title="androidjava"/>
                            </div>
                            <p>Android-Java </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sqlite} alt="sqlite" title="sqlite"/>
                            </div>
                            <p>	SQLite</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={Net} alt="Net" title="Net"/>
                            </div>
                            <p>.Net</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default selfvehicleinspection;
