import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import insurance from "../../assets/images/casestudies/insurance.jpg";
import SurepathDigital from "../../assets/images/banner/surepathdigital-banner.jpg";
import PolicyIssuance from "../../assets/images/casestudies/policy-issuance.jpg";
import datacollect from "../../assets/images/banner/data-collection-banner.jpg";
import interviewmanagement from "../../assets/images/banner/interview-management-banner.jpg";
import CourseDelivery from "../../assets/images/banner/course-delivery-system-banner.jpg";
import ScreeningBackground from "../../assets/images/banner/screening-background-check-services-banner.jpg";
import IntegrationFramework from "../../assets/images/banner/integration-framework-banner.jpg";
import HomeWarranty from "../../assets/images/banner/home-warranty-services-banner.jpg";
import SalesOptimus from "../../assets/images/banner/sales-optimus-banner.jpg";
import LogisticsDistribution from "../../assets/images/banner/logistics-distribution-banner.jpg";
import AutoAncillary from "../../assets/images/banner/auto-ancillary-manufacturing-company-banner.jpg";
import ngo from "../../assets/images/banner/non-governmental-organizations-banner.jpg";
import PrinterManufacturer from "../../assets/images/banner/printer-manufacturer-banner.jpg";
import MedicalDevices from "../../assets/images/banner/medical-devices-erp-banner.jpg";
import InsuranceBonding from "../../assets/images/banner/insurance-e-bonding-processe-banner.jpg";
import WellnessServices from "../../assets/images/banner/wellness-services-banner.jpg";
import AutoAncillaryManufacturing from "../../assets/images/banner/auto-ancillary-manufacturing-company-banner.jpg";
import Manufacturing from "../../assets/images/banner/manufacturing-advances-textiles-for-automotive-sector-banner.jpg";
import SmartWaterFilters from "../../assets/images/banner/smart-water-filters-banner.jpg";
import MobileEcommerceStore from "../../assets/images/banner/mobile-ecommerce-store-banner.jpg";
import MortgageVendor from "../../assets/images/banner/mortgage-vendor-management-banner.jpg";
import HealthandInsurance from "../../assets/images/banner/health-and-insurance-banner.jpg";
import LogisticsPlatform from "../../assets/images/banner/logistics-platform-banner.jpg";
import Claimmanagement from "../../assets/images/banner/claim-management-banner.jpg";
import SelfVehicle from "../../assets/images/banner/self-vehicle-inspection-banner.jpg";
import ConnectedtoCrop from "../../assets/images/banner/connected-to-crop-banner.jpg";
import InsuranceSolution from "../../assets/images/banner/insurance-solution-banner.jpg";
import CentralEstimation from "../../assets/images/banner/central-estimation-banner.jpg";
import UnifiedServices from "../../assets/images/banner/unified-services-banner.jpg";
import Engineeringandtechnology from "../../assets/images/banner/engineering-and-technology-solution-banner.jpg";
import EnterpriseDevelopment from "../../assets/images/banner/enterprise-development-and-online-training-banner.jpg";
import PerformanceTesting from "../../assets/images/banner/performance-testing-banner.jpg";
import AutomationTesting from "../../assets/images/casestudies/automation-testing.jpg";
import SecurityTesting from "../../assets/images/banner/security-testing-banner.jpg";
import TestCaseManagement from "../../assets/images/banner/test-case-management-banner.jpg";
import Platformglobal from "../../assets/images/casestudies/platform-global.jpg";
import CollaborativeExtranet from "../../assets/images/banner/collaborative-extranet-banner.jpg";
import casestudybanner from "../../assets/images/banner/casestudy-banner.jpg";
import CustomBreadcrumb from '../BreadCrumbs/CustomebreadCrumb';
import reading from "../../assets/images/animated-icons/fast-forward.gif";
import Financeservices from "../../assets/images/banner/finance-services.jpg";
import Constructionmanufacturing from "../../assets/images/banner/construction-manufacturing.jpg";
import Manufacturingchemical from "../../assets/images/banner/manufacturing-chemical-production.jpg";
import Manufacturingbanner from "../../assets/images/banner/manufacturing.jpg";
import Retailtrading from "../../assets/images/banner/retail-trading.jpg";
import Automotivesector from "../../assets/images/banner/automotive-sector.jpg";
import { Helmet } from "react-helmet";

  

const casestudies = () => {
  const breadcrumbItems = [
    { label: 'Home', link: '/' },
    { label: 'Case Studies' },
    // { label: 'AI, BI & Data' },

  ];

  const scrollToTop = () => {
    // Scrolls to the top of the page when called
    window.scrollTo(0, 0);
  };


  return (
    <>
     <Helmet>
        <title>
        Software development company | Case studies | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Get the latest thought leadership on technology topics from Synoverge Case Studies, including Software development, Product Engineering, Artificial Intelligence, Cloud, Big Data, IoT, Security, and more."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies" />
      </Helmet>
     <div className="case-study">
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Case Study</h1>
                  {/* <h1>Our Success Stories</h1> */}
                  <p>Collaborating together for resolutions of business problems across the world</p>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={casestudybanner} alt="case-study" title="case-study" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <div className="module">
          <Container>
          <CustomBreadcrumb items={breadcrumbItems} />
            <Row>
              <Col md={12}>
                <h3>Product & Platform Engineering</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={insurance} alt="insurance" title="insurance" />
                  </div>
                  <h6 className="icon-box-title">
                    Insurance & e-Bonding process
                  </h6>
                  <div className="icon-box-content">
                    <p>
                      The project is an electronic guarantee bond handling
                      framework that allows clients, merchants/specialists, and
                      carriers to efficiently{" "}
                    </p>
                  </div>
                  <Link to="./insurance-e-bonding-process" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={SurepathDigital}
                      alt="SurepathDigital"
                      title="SurepathDigital"
                    />
                  </div>
                  <h6 className="icon-box-title">SurepathDigital</h6>
                  <div className="icon-box-content">
                    <p>
                      Our client has extensive experience in the surety market,
                      insurance corporate finance, and the creation of
                      insurance-related systems.
                    </p>
                  </div>
                  <Link to="./surepathdigital" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={PolicyIssuance}
                      alt="Policy-Issuance"
                      title="Policy-Issuance"
                    />
                  </div>
                  <h6 className="icon-box-title">
                    Online Real-time Policy Issuance Engine Portal
                  </h6>
                  <div className="icon-box-content">
                    <p>
                      A purpose online web application platform that allows all
                      intermediaries and agents to use and issue policies in
                      real time with the backend system.
                    </p>
                  </div>
                  <Link to="./online-real-time-policy-issuance-engine-portal" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={datacollect}
                      alt="data-collect"
                      title="data-collect"
                    />
                  </div>
                  <h6 className="icon-box-title">Data Collection Platform</h6>
                  <div className="icon-box-content">
                    <p>
                      Client needs to construct a dynamic form to take diverse
                      information from different sorts of users and collect data
                      for analysis, however no available solution/platform...
                    </p>
                  </div>
                  <Link to="./data-collection-platform" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={interviewmanagement}
                      alt="interview-management"
                      title="interview-management"
                    />
                  </div>
                  <h6 className="icon-box-title">
                    Interview Management Platform
                  </h6>
                  <div className="icon-box-content">
                    <p>
                      The main focus of this interview management system is on
                      dealing with applicant interviews, records, and so forth.
                      In addition, the system displays all of the candidate
                      lists...
                    </p>
                  </div>
                  <Link to="./interview-management-platform-2" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={CourseDelivery}
                      alt="Course-Delivery"
                      title="Course-Delivery"
                    />
                  </div>
                  <h6 className="icon-box-title">Course Delivery System</h6>
                  <div className="icon-box-content">
                    <p>
                      Discrimination prevention and Sexual harassment, diversity
                      awareness and inclusion, uncovering implicit bias, lawful
                      hiring, data security and privacy...
                    </p>
                  </div>
                  <Link to="./course-delivery-system" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={ScreeningBackground}
                      loading="lazy"
                      alt="Screening-Background"
                      title="Screening-Background"
                    />
                  </div>
                  <h6 className="icon-box-title">
                    Screening & Background Check Services
                  </h6>
                  <div className="icon-box-content">
                    <p>
                      Usability and a modern user interface , Remove any
                      external reliance and Easy upgrades with your own solution
                      External screening is simple, and ATS and HRIS...
                    </p>
                  </div>
                  <Link to="./screening-background-check-services" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={IntegrationFramework}
                      loading="lazy"
                      alt="Integration-Framework"
                      title="Integration-Framework"
                    />
                  </div>
                  <h6 className="icon-box-title">Integration Framework</h6>
                  <div className="icon-box-content">
                    <p>
                      With several offices across India, the company has a
                      strong centrally automated business process flow that runs
                      from consumer.
                    </p>
                  </div>
                  <Link to="./integration-framework"  className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="module module-gray">
          <Container>
            <Row>
              <Col md={12}>
                <h3>Enterprise Applications</h3>
              </Col>
            </Row>
            <Row>
            <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                  <img src={Financeservices} loading="lazy" alt="Financial Services" title="Financial Services" />
                  </div>
                  <h6 className="icon-box-title">
                  Financial Services
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Our client is committed to creating a positive impact by delivering timely, cost-effective credit to approximately 500 million underserved borrowers. 
                    </p>
                  </div>
                  <Link to="./financial-services" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                  <img src={Constructionmanufacturing} loading="lazy" alt="Construction and Manufacturing" title="Construction and Manufacturing" />
                  </div>
                  <h6 className="icon-box-title">Construction and Manufacturing</h6>
                  <div className="icon-box-content">
                    <p>
                    Their holistic approach to construction, coupled with an unwavering commitment to quality, firmly establishes our client.
                    </p>
                  </div>
                  <Link to="./construction-and-manufacturing" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                  <img src={Manufacturingchemical} loading="lazy" alt="Manufacturing and Chemical Production" title="Manufacturing and Chemical Production" />
                  </div>
                  <h6 className="icon-box-title">
                  Manufacturing and Chemical Production
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    The client's diverse portfolio spans a wide array of industrial products, showcasing their versatility and adaptability to various sectors.
                    </p>
                  </div>
                  <Link to="./manufacturing-and-chemical-production" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                  <img src={Manufacturingbanner} loading="lazy" alt="Manufacturing" title="Manufacturing" />
                  </div>
                  <h6 className="icon-box-title">
                  Manufacturing
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    The multinational corporation, headquartered in Japan, operates across multiple industries with a distinctive focus on iron and steel, welding.
                    </p>
                  </div>
                  <Link to="./manufacturing" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={Retailtrading} loading="lazy" alt="Retail & Trading" title="Retail & Trading" />

                  </div>
                  <h6 className="icon-box-title">
                  Retail and Trading
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    The client has established itself as a leading provider in the industry, delivering quality products to meet the varied needs of its consumer base.
                    </p>
                  </div>
                  <Link to="./retail-and-trading" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={HomeWarranty} loading="lazy" alt="Home-Warranty" title="Home-Warranty" />
                  </div>
                  <h6 className="icon-box-title">
                  Home Warranty Services - F&O
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    The Company was founded in the United States 25 years ago, with the goal of developing ground-breaking risk management solutions to solve the complexities...
                    </p>
                  </div>
                  <Link to="./home-warranty-services-fo" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={SalesOptimus} loading="lazy" alt="Sales-Optimus" title="Sales-Optimus" />
                  </div>
                  <h6 className="icon-box-title">
                  Home Warranty Services - Sales Optimus
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    The Company began 25 years ago in the United States, inventing ground-breaking risk management solutions to address the complexity that...
                    </p>
                  </div>
                  <Link to="./home-warranty-services-sales-optimus" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={LogisticsDistribution} loading="lazy" alt="Logistics-Distribution" title="Logistics-Distribution" />
                  </div>
                  <h6 className="icon-box-title">
                  Logistics and Distribution
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Customer is a renowned courier and logistics firm. Following the adoption of GST in India, the customer formed 30 separate entities in their legacy system... 
                    </p>
                  </div>
                  <Link to="./logistics-and-distribution" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={AutoAncillary} loading="lazy" alt="Auto-Ancillary" title="Auto-Ancillary" />
                  </div>
                  <h6 className="icon-box-title">
                  Auto Ancillary Manufacturing company
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Power semiconductor devices, electric power systems, automotive electronics goods, and solenoid items are all manufactured by this firm. 
                    </p>
                  </div>
                  <Link to="./auto-ancillary-manufacturing-company" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={ngo} loading="lazy" alt="Non-governmental organizations" title="Non-governmental organizations" />
                  </div>
                  <h6 className="icon-box-title">
                  Non-governmental organizations
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    NGO web-based application designed to help non-profit organisations manage their numerous operations. 
                    </p>
                  </div>
                  <Link to="./non-governmental-organizations" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={PrinterManufacturer} loading="lazy" alt="Printer-Manufacturer" title="Printer-Manufacturer" />
                  </div>
                  <h6 className="icon-box-title">
                  Printer Manufacturer
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Printer manufacturing company desire to develop ERP where all the subsidiary company handled by the headquarters general rules so company.
                    </p>
                  </div>
                  <Link to="./printer-manufacturer" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={MedicalDevices} loading="lazy" alt="Medical-Devices" title="Medical-Devices" />
                  </div>
                  <h6 className="icon-box-title">
                  Medical Devices ERP
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    This project is a cost-effective enterprise resource management system designed specifically for the medical device industry. 
                    </p>
                  </div>
                  <Link to="./medical-devices-erp" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={InsuranceBonding} loading="lazy" alt="Insurance-e-Bonding" title="Insurance-e-Bonding" />
                  </div>
                  <h6 className="icon-box-title">
                  Insurance & e-Bonding processe
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    The project is an electronic guarantee bond handling framework that allows clients, merchants/specialists, and carriers to efficiently 
                    </p>
                  </div>
                  <Link to="./insurance-e-bonding-process" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={WellnessServices} loading="lazy" alt="Wellness-Services" title="Wellness-Services" />
                  </div>
                  <h6 className="icon-box-title">
                  Wellness Services
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    The company's main markets are the United States and Canada, and its exams are utilised by helping professionals all over the globe to screen.
                    </p>
                  </div>
                  <Link to="./wellness-services" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={AutoAncillaryManufacturing} loading="lazy" alt="Auto-Ancillary-Manufacturing" title="Auto-Ancillary-Manufacturing" />
                  </div>
                  <h6 className="icon-box-title">
                  Auto Ancillary Manufacturing company – India
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Top motor vehicle manufacturers in India, the United States, and the United Kingdom employ the manufactured parts in their vehicles. 
                    </p>
                  </div>
                  <Link to="./auto-ancillary-manufacturing-company-india" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                  <img src={Automotivesector} loading="lazy" alt="Manufacturing Advances Textiles for Automotive Sector
" title="Manufacturing  Advances Textiles for Automotive Sector" />
                  </div>
                  <h6 className="icon-box-title">
                  Manufacturing Advances Textiles for...
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Customer has chosen to adopt Microsoft Dynamics AX 2012 at a global level, with us providing Development and Maintenance.
                    </p>
                  </div>
                  <Link to="./manufacturing-advances-textiles-for-automotive-sector-2" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              
            </Row>
          </Container>
        </div>
        <div className="module">
          <Container>
            <Row>
              <Col md={12}>
                <h3>IOT, Mobility & Devices</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={SmartWaterFilters} loading="lazy" alt="Smart-Water-Filters" title="Smart-Water-Filters" />
                  </div>
                  <h6 className="icon-box-title">
                  Smart Water Filters
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    They are driving in the modern and business water cleansing and giving different sorts of water channel. Their point is to help in further developing the water quality...
                    </p>
                  </div>
                  <Link to="./smart-water-filters" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={MobileEcommerceStore}
                      loading="lazy"
                      alt="Mobile-Ecommerce-Store"
                      title="Mobile-Ecommerce-Store"
                    />
                  </div>
                  <h6 className="icon-box-title">Mobile Ecommerce Store</h6>
                  <div className="icon-box-content">
                    <p>
                    They wanted to create a mobile application that would allow their clients to browse through different natural goods, learn about them...
                    </p>
                  </div>
                  <Link to="./mobile-ecommerce-store" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={MortgageVendor}
                      loading="lazy"
                      alt="Mortgage-Vendor"
                      title="Mortgage-Vendor"
                    />
                  </div>
                  <h6 className="icon-box-title">
                  Mortgage Vendor Management
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    They intended to expand their offering to mobile, which may help with business process automation and workflow customization. They assist their client with expanding...
                    </p>
                  </div>
                  <Link to="./mortgage-vendor-management" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={HealthandInsurance}
                      loading="lazy"
                      alt="Health-and-Insurance"
                      title="Health-and-Insurance"
                    />
                  </div>
                  <h6 className="icon-box-title">Health and Insurance</h6>
                  <div className="icon-box-content">
                    <p>
                    Health insurance company wanted to developed a mobile application which help their customer’s to preventing illnesses reduces healthcare costs.
                    </p>
                  </div>
                  <Link to="./health-and-insurance" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={LogisticsPlatform}
                      loading="lazy"
                      alt="Logistics-Platform"
                      title="Logistics-Platform"
                    />
                  </div>
                  <h6 className="icon-box-title">
                  Logistics Platform
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Load transportation is still a difficulty, from choosing the correct carrier to getting the load delivered. 
                    </p>
                  </div>
                  <Link to="./logistics-platform" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={Claimmanagement}
                      loading="lazy"
                      alt="Claim-management"
                      title="Claim-management"
                    />
                  </div>
                  <h6 className="icon-box-title">Claim management (Insurance)</h6>
                  <div className="icon-box-content">
                    <p>
                    A formal request to an insurance company for payment based on the provisions of the insurance policy is called an insurance claim. 
                    </p>
                  </div>
                  <Link to="./claim-management-insurance" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={SelfVehicle}
                      loading="lazy"
                      alt="Self-Vehicle"
                      title="Self-Vehicle"
                    />
                  </div>
                  <h6 className="icon-box-title">
                  Self Vehicle Inspection
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    When a consumer demand for a renew policy for an insurance, the consumer must wait a long time for the survey, which requires the surveyor to come... 
                    </p>
                  </div>
                  <Link to="./self-vehicle-inspection" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img
                      src={ConnectedtoCrop}
                      loading="lazy"
                      alt="Connected-to-Crop"
                      title="Connected-to-Crop"
                    />
                  </div>
                  <h6 className="icon-box-title">Connected to Crop</h6>
                  <div className="icon-box-content">
                    <p>
                    Most farmers lose money on their farm because their predictions about weather, crop prices on the market, and other factors are virtually always wrong. 
                    </p>
                  </div>
                  <Link to="./connected-to-crop" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="module module-gray">
          <Container>
            <Row>
              <Col md={12}>
                <h3>Microsoft Technologies</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={InsuranceSolution} loading="lazy" alt="Insurance-Solution" title="Insurance-Solution" />
                  </div>
                  <h6 className="icon-box-title">
                  Insurance Solution
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    The client is devoted to raising awareness regarding Health, Safety, Security, and the Environment due to the nature of the business. The corporation continues...
                    </p>
                  </div>
                  <Link to="./insurance-solution" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={CentralEstimation} loading="lazy" alt="Central-Estimation" title="Central-Estimation" />
                  </div>
                  <h6 className="icon-box-title">
                  Central Estimation Platform for Infrastructure...
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    In more than 70 countries throughout the world, the customer provides different infrastructure management services to its clients. The effectiveness of the customer...
                    </p>
                  </div>
                  <Link to="./central-estimation-platform-for-infrastructure-management-services" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={UnifiedServices} loading="lazy" alt="Unified-Services" title="Unified-Services" />
                  </div>
                  <h6 className="icon-box-title">
                  Unified Services Platform a global ISV
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Our client envisaged an Industrialized method for offering infrastructure services, deployment, and upgrade services to its clients worldwide as part of a three-year strategic...
                    </p>
                  </div>
                  <Link to="./unified-services-platform-a-global-isv" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={Engineeringandtechnology} loading="lazy" alt="Engineering-and-technology" title="Engineering-and-technology" />
                  </div>
                  <h6 className="icon-box-title">
                  Engineering and technology solution provider...
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Client is a supplier of engineering and technology solutions that increase the energy and operational efficiency of buildings, vehicle
                    </p>
                  </div>
                  <Link to="./engineering-and-technology-solution-provider-that-provides-goods-and-services" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={EnterpriseDevelopment} loading="lazy" alt="Enterprise-Development" title="Enterprise-Development" />
                  </div>
                  <h6 className="icon-box-title">
                  Enterprise Development and Online Training
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    It serves clients in a variety of industries, including higher education, automotive and transportation services, retail and consumer products.
                    </p>
                  </div>
                  <Link to="./enterprise-development-and-online-training" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="module">
          <Container>
            <Row>
              <Col md={12}>
                <h3>Verification & Validation</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={PerformanceTesting} loading="lazy" alt="Performance-Testing" title="Insurance-Solution" />
                  </div>
                  <h6 className="icon-box-title">
                  Performance Testing
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Several of US firms use the customer's online compliance training for sexual harassment, diversity, safety, ethics, and other topics.
                    </p>
                  </div>
                  <Link to="./performance-testing" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={AutomationTesting} loading="lazy" alt="Automation-Testing" title="Automation-Testing" />
                  </div>
                  <h6 className="icon-box-title">
                  Automation Testing
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Consultancy that gives horticultural and monetary guidance to farmers. They've been serving cultivating organizations and prerequisites
                    </p>
                  </div>
                  <Link to="./automation-testing" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={SecurityTesting} loading="lazy" alt="Security-Testing" title="Security-Testing" />
                  </div>
                  <h6 className="icon-box-title">
                  Security Testing
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Audit and assurance, consulting, financial advising, risk advisory, tax, and associated services are the major areas of business
                    </p>
                  </div>
                  <Link to="./security-testing" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={TestCaseManagement} loading="lazy" alt="Test-Case-Management" title="Test-Case-Management" />
                  </div>
                  <h6 className="icon-box-title">
                  Test Case Management 
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Most farmers lose money on their farm because their predictions about weather, crop prices on the market, and other factors are virtually always wrong. 
                    </p>
                  </div>
                  <Link to="./test-case-management" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="module module-gray">
          <Container>
            <Row>
              <Col md={12}>
                <h3>Open Source Technologies</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={Platformglobal} loading="lazy" alt="Platform-global" title="Platform-global" />
                  </div>
                  <h6 className="icon-box-title">
                  Unified Services Platform a global ISV
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    Our client envisaged an Industrialized method for offering infrastructure services, deployment, and upgrade services to its clients worldwide as part of a three-year strategic...
                    </p>
                  </div>
                  <Link to="./unified-services-platform-a-global-isv" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <img src={CollaborativeExtranet} loading="lazy" alt="Collaborative-Extranet" title="Collaborative-Extranet" />
                  </div>
                  <h6 className="icon-box-title">
                  Collaborative Extranet Product Suite - IR
                  </h6>
                  <div className="icon-box-content">
                    <p>
                    The client is a Michigan-based product company that specializes in developing B2B and B2C solutions for collaboration across industrial verticals...
                    </p>
                  </div>
                  <Link to="./collaborative-extranet-product-suite" className="icon-box-link " onClick={scrollToTop}>Continue Reading <img src={reading} alt="banner" loading="lazy" className="animate-service-icon" />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    
    </>
  )
}

export default casestudies;