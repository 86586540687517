
  import React, { useState } from 'react';
  import '../life-synoverge/life-synoverge.scss';
  import ImageModal from './ImageModal'; // Adjust the import path as needed

  function Items({ data }) {
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowModal(true);
    };

    return (
      <div className="row">
        {data.map((value, index) => {
          const { id, image, title, description } = value;
          return (
            <div className="col-sm-4" key={id}>
              <div
                className="card text-center bg-light card-gallery"
                onClick={() => handleImageClick(index)} // Open modal on image click
              >
                <img src={image} className="img-fluid" />
                {/* <h3> {title} </h3>
                <p>{description}</p> */}
              </div>
            </div>
          );
        })}
        <ImageModal
          show={showModal}
          onHide={() => setShowModal(false)}
          images={data.map((value) => value.image)}
          selectedImageIndex={selectedImageIndex}
        />
      </div>
    );
  }

  export default Items;
