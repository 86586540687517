import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import sales from "../../../assets/images/casestudies/sales.png";
import dmi from "../../../assets/images/casestudies/dmi.png";
import homewarrantyservicebanner from "../../../assets/images/banner/sales-optimus-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";




function homewarrantyservicessalesoptimus() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Home Warranty Services - Sales Optimus' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Software development company | Case studies | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Get the latest thought leadership on technology topics from Synoverge Case Studies, including Software development, Product Engineering, Artificial Intelligence, Cloud, Big Data, IoT, Security, and more."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/home-warranty-services-sales-optimus" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Home Warranty Services - Sales Optimus</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={homewarrantyservicebanner} alt="home-warranty-services" title="home-warranty-services" />
                </div>                
              </Col>
            </Row>
          </Container>
      </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Client is a Virginia based company into new home warranty administrator and provider of innovative products and outstanding customer service in the United States.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The Company began 25 years ago in the United States, inventing ground-breaking risk management solutions to address the complexity that America's new home builders face. Establishing out as a warranty administrative service provider, the company has evolved into what it is now. Client is committed to becoming an essential risk management partner in the new home construction industry.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The main challenge is to upgrade from their current broken version to a much better and upgraded version that will help them meet their business needs and improve operations in order to keep up with ever-changing technologies and customer requirements in order to provide the best services to their customers and have a builder portal where their customers/builders can load the lots/units sold by them, enrol themselves for the products, and check the exact status of the products.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>A clean and show-case application for the builders, with data flowing effortlessly between applications to allow for speedy decision-making. The warranty enrolment process is error-free and rapid, giving you a competitive advantage over your competitors.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sales} alt="sales" title="sales"/>
                            </div>
                            <p>Microsoft Dynamics Sales Application</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={dmi} alt="msdynamics" title="msdynamics"/>
                            </div>
                            <p>Microsoft Dynamics 365 Power Portal</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default homewarrantyservicessalesoptimus;
