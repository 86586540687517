import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import ax2009 from "../../../assets/images/casestudies/ax2009.png";
import mscrm from "../../../assets/images/casestudies/mscrm.jpg";
import microstag from "../../../assets/images/casestudies/micro-stag.png";
import sharepoint from "../../../assets/images/casestudies/sharepoint.jpg";
import medicaldeviceserpbanner from "../../../assets/images/banner/medical-devices-erp-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";




function medicaldeviceserp() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Medical Devices ERP' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Medical Devices ERP | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Customer is a medical device firm established in Japan that is a subsidiary of a US-based corporation. AX 2012 ERP was recently installed, with extensive changes and integration with a third-party logistics system."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/medical-devices-erp" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Medical Devices ERP</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={medicaldeviceserpbanner} alt="medical-devices-erp" title="medical-devices-erp" />
                </div>                
              </Col>
            </Row>
          </Container>
      </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Customer is a medical device firm established in Japan that is a subsidiary of a US-based corporation. AX 2012 ERP was recently installed, with extensive changes and integration with a third-party logistics system.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>This project is a cost-effective enterprise resource management system designed specifically for the medical device industry. The application system comprises of a central database that stores all of a medical devices’ ERP-related data. The system has a user-friendly graphical user interface that allows users to simply input supply and demand data.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>AX and the logistics site have been integrated. Data communication with many external portals using an EDI and integration technique, Developed a batch task that concurrently produces transactions in logistic and AX and auto-posts sales invoices, as well as the logic for recognising high-value sales and inventory movement.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The project result is smoothing out of key cycles for smoother operations, Coordination of CRM Contacts with LinkedIn Sales Navigator for simplicity of lead management, Joining of Vonage with Contacts and Accounts for smoother case the board and call tracking, Incorporated business process from Lead to Invoice and Focal record storehouse which diminishes the duplication.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={ax2009} alt="ax2009" title="ax2009"/>
                            </div>
                            <p>MS Dynamics AX 2009</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={mscrm} alt="mscrm" title="mscrm"/>
                            </div>
                            <p>MS Dynamics CRM 2016</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={microstag} alt="micro-stag" title="micro-stag"/>
                            </div>
                            <p>Micro Strategy 9.4.1</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sharepoint} alt="sharepoint" title="sharepoint"/>
                            </div>
                            <p>MS SharePoint 2012</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default medicaldeviceserp;
