import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import yslow from "../../../assets/images/casestudies/yslow.png";
import jmeter from "../../../assets/images/casestudies/jmeter.png";
import lighthouse from "../../../assets/images/casestudies/lighthouse.png";
import vstspng from "../../../assets/images/casestudies/vstspng.png";
import performancetestingbanner from "../../../assets/images/banner/performance-testing-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";




function performancetesting() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Performance Testing' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Performance Testing | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="The customer provide various online compliance training for harassment, diversity, safety, ethics courses to thousands of US organizations. Several of US firms use the customer's online compliance training for harassment, diversity, safety, ethics, and other topics."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/performance-testing" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Performance Testing</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={performancetestingbanner} alt="Performance Testing" title="Performance Testing" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The customer provide  various online compliance training for harassment, diversity, safety, ethics  courses to thousands of US organizations.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Several of US firms use the customer's online compliance training for harassment, diversity, safety, ethics, and other topics.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The difficult areas  are Individual course content includes huge graphics, flash, and video files (MP4), among other things, Each lesson is around 100 pages long, With 10,000 concurrent users, the application is certified and users' test environment is set up. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The entire programme has been re-ramped from ASP to MVC technology. This feature includes ideas like as caching, webAPI, and sprite images. Incorporated as well. The application's sole non-functional and important success requirements are that it work with and can handle a load of 10,000 concurrent users.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={yslow} alt="yslow" title="yslow"/>
                            </div>
                            <p>YSlow tool</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={jmeter} alt="jmeter" title="jmeter"/>
                            </div>
                            <p>Jmeter </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={lighthouse} alt="lighthouse" title="lighthouse"/>
                            </div>
                            <p>Lighthouse </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={vstspng} alt="vstspng" title="vstspng"/>
                            </div>
                            <p>VSTS</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default performancetesting;
