import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "../pages/pages.scss";
import MSDynamics365 from "../../assets/images/blog/ms-dynamics-365.jpg";
import point6 from "../../assets/images/blog/point-6.png";
import point10 from "../../assets/images/blog/point-10.png";
import point13 from "../../assets/images/blog/point-13.png";
import point18 from "../../assets/images/blog/point-18.png";
import point22 from "../../assets/images/blog/point-22.png";
import point26 from "../../assets/images/blog/point-26.png";
import point28 from "../../assets/images/blog/point-28.png";
import point31 from "../../assets/images/blog/point-31.png";
import point331 from "../../assets/images/blog/point-33-1.png";
import point332 from "../../assets/images/blog/point-33-2.png";
import point41 from "../../assets/images/blog/point-41.png";
import point42 from "../../assets/images/blog/point-42.png";
import point43 from "../../assets/images/blog/point-43.png";
import dynamics365scaledbanner from "../../assets/images/banner/dynamics-365-scaled-banner.jpg";
import CustomBreadcrumb from '../BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";

function accrualscheme() {
  const breadcrumbItems = [
    { label: 'Home', link: '/' },
    { label: 'Blog', link:'/blog' },
    { label: 'Accrual Scheme in Microsoft Dynamics 365 FO' },
  ];
  return (
      <div className="blog-content">
         <Helmet>
        <title>
        Accrual Scheme in Microsoft Dynamics 365 FO | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="In today’s blog we will talk about “accrual scheme” feature which is natively available in Dynamics 365 FO which most of the companies does not explore though they have the requirement for the same."
        />
        <link rel="canonical" href="https://www.synoverge.com/blog/accrual-scheme-in-microsoft-dynamics-365-fo" />
      </Helmet>
        <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Accrual Scheme in Microsoft Dynamics 365 FO</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={dynamics365scaledbanner} alt="ourstory" title="ourstory" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <div className="module">
          <Container>
          <CustomBreadcrumb items={breadcrumbItems} />
            <Row>
              <h3>Overview</h3>
                <Col xs={12} lg={6}>
                    <div className="Job-content"> 
                        <p>In today’s blog we will talk about “accrual scheme” feature which is natively available in Dynamics 365 FO (Also available in AX 2012) which most of the companies does not explore though they have the requirement for the same.</p>
                        <p>Before moving forward let’s first understand the purpose and concept behind using Accrual scheme.</p>
                        <p>Accruals are used in accrual accounting to track revenue that is recognized in the period that it's earned in, not when the actual payment is received, and same concept applies to track expenses (costs) that are only recognized when they occur, not when the actual payment is made.</p>
                        <p>In most of the organization we have transactions where we pay in advance for a specific expense and that point of time, we record that as advance and then moving forward we recognize the expense by reversing advance or prepaid on Monthly basis. So, let’s understand this with one example.</p>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="Job-content">
                          <img src={MSDynamics365} alt="MS-Dynamics-365" title="MS-Dynamics-365" className="w-100" />
                    </div>
                </Col>
            </Row>
            <Row>
              <Col md={12}>
                  <div className="Job-content">
                      <p>Use Case: Let’s say a company buys the Software License subscription of $647 for 3 months on “1st May 2021” though they will record the invoice on 1st May and pay the amount to vendor but customer (We as a Company) cannot show that as “Expense in P&L” for the month of “May 2021”. So, first it has to be recorded as Balance sheet item as prepaid and then every month for the duration of subscription we have to reverse the prepaid and post the “Expense” for a specific month. Here are the expected Accounting entries:</p>
                      <Table>
                        <tbody>
                            <tr>
                              <th>
                              At the time of Invoice Booking:
                              </th>
                            </tr>
                            <tr>
                              <td>License Cost A/C</td>
                              <td>$647</td>
                            </tr>
                            <tr>
                              <td>To Vendor A/C</td>
                              <td>$647</td>
                            </tr>
                        </tbody>
                      </Table>
                      <Table>
                        <tbody>
                            <tr>
                              <th>
                              Prepaid schedule for 3 months to recognize expenses, so for next 3 months’ entries will be:
                              </th>
                            </tr>
                            <tr>
                              <td>Prepaid Expense A/C</td>
                              <td>($647 / 3)</td>
                            </tr>
                            <tr>
                              <td>To License Cost A/C</td>
                              <td>($647 / 3)</td>
                            </tr>
                        </tbody>
                      </Table>
                  </div>
                  <div className="Job-content">
                      <p>Above discussed scenario can be solved using with one-time setup of “Accrual Scheme” feature for Dynamics 365.</p>
                      <p>Follow the below procedure to setup Accrual schemes for prepaid expenses.</p>
                      <ul>
                          <li>1. Open Accrual Schemes. <br/> <strong>Path: General ledger&gt;&gt;Journal Setup&gt;&gt;Accrual Schemes.</strong> </li>
                          <li>2. Click on <strong>New</strong> </li>
                          <li>3. Enter the name you want to give to Accrual Scheme in  <strong>Accrual Identification</strong> </li>
                          <li>4. Enter description of Accrual Scheme in  <strong>Description of Accrual Scheme</strong> </li>
                          <li>5. Click on <strong>Debit</strong> and enter Account you want to get debited at the time of booking the prepaid entry. Usually the account selected here will be the balance sheet Account where Advance or Prepaid will be recorded. </li>
                          <li>6. Click on <strong>Credit </strong>and select the Main Account you want to Credit moving forward into the months i.e., the actual expense account. </li>
                      </ul>
                      <p>
                        <img src={point6} alt="" className="w-100" />
                      </p>
                  </div>
                  <div className="Job-content">
                      <ul>
                        <li>7. Click on <strong>Voucher</strong> and from dropdown menu select <strong>‘New voucher for accrual transactions’</strong> </li>
                        <li>8. Click on <strong>Number Series</strong>  and select appropriate number series. </li>
                        <li>9. Click on <strong>description </strong>and add description for the Accrual Scheme. </li>
                        <li>10. Click on <strong>Accrual Basis</strong>  and form dropdown select <strong>‘Calendar’</strong> </li>
                      </ul>
                      <p>
                        <img src={point10} alt="" className="w-100" />
                      </p>
                  </div>
                  <div className="Job-content">
                      <ul>
                        <li>11. Click on <strong>Period Frequency</strong> and from drop-down select Monthly. For this transaction we want to post Accrual journal every month that is why we have selected Monthly. </li>
                        <li>12. Click on <strong>Number of Occurrences by Period</strong>  and enter period. Here, we want to post Accrual Journal for 3 Months that is why we have selected Number of Occurrences as 3. If you want to post for 12 months, then enter ‘12’ here and so on.</li>
                        <li>13. Click on <strong>Post Transaction</strong> and from drop-down menu select Month. </li>
                      </ul>
                      <p>
                        <img src={point13} alt="" className="w-100" />
                      </p>
                  </div>
                  <div className="Job-content">
                      <ul>
                        <li>
                            <ul>
                              <li>14. Click on <strong>Post in Week, Month, or quarter</strong> and from dropdown select <strong>Beginning</strong> </li>
                              <li>15. Here, we have three option to select from,
                                <ul className="icon-list">
                                    <li>Beginning – 1st of every month</li>
                                    <li>Middle – 15th of every month</li>
                                    <li>End – 30th /31st of every month</li>
                                </ul>
                              </li>
                              <li>16. Click on <strong>Spread Month and Quarter Values</strong> and select <strong>Evenly </strong>from drop-down. </li>
                              <li>17. Here, we have two options to select from,
                                <ul className="icon-list">
                                    <li>Evenly – Divide the total amount equally according to months mentioned.</li>
                                    <li>Number of days – Calculated the amount according to the no. of days.</li>
                                </ul>
                              </li>
                              <li>18. Click on <strong>Save</strong> </li>
                            </ul>
                        </li>
                      </ul>
                      <p>
                        <img src={point18} alt="" className="w-100" />
                      </p>
                  </div>
                  <div className="Job-content">
                      <ul>
                        <li>19. Now, Go to Vendor Invoice Journal. <br/> <strong>Path: Account Payable&gt;&gt;Invoice&gt;&gt;Invoice Journal.</strong> </li>
                        <li>20. Click on <strong>New</strong> </li>
                        <li>21. Select <strong>AP Invoice</strong> form dropdown. </li>
                        <li>22. Click on <strong>Lines</strong> </li>
                      </ul>
                      <p>
                        <img src={point22} alt="" className="w-100" />
                      </p>
                  </div>
                  <div className="Job-content">
                      <ul>
                        <li>23. Click on Date and select a date for the transaction.</li>
                        <li>24. Click on Account and select the Vendor for the transaction.</li>
                        <li>25. Click on Invoice Date and enter a date for the transaction.</li>
                        <li>26. Click on Invoice and enter Invoice Number.</li>
                      </ul>
                      <p>
                        <img src={point26} alt="" className="w-100" />
                      </p>
                  </div>
                  <div className="Job-content">
                      <ul>
                        <li>27. Click on Credit and enter the amount.</li>
                        <li>28. Click on Offset Account and select Offset account along with the Financial dimensions.</li>
                      </ul>
                      <p>
                        <img src={point28} alt="" className="w-100" />
                      </p>
                  </div>
                  <div className="job-content">
                      <ul>
                        <li>29. Click on Functions just above the Journal Line.</li>
                        <li>30. Click on Ledger Accrual from dropdown menu.</li>
                        <li>31. A new side window will open.</li>
                      </ul>
                      <p>
                        <img src={point31} alt="" className="w-100" />
                      </p>
                  </div>
                  <div className="job-content">
                      <ul>
                        <li>32. Click on Accrual Identification from dropdown select the Accrual Scheme which you want. In our case it was Licence.</li>
                        <li>33. Other details will reflect automatically. You do not need to change it manually unless necessary.</li>
                      </ul>
                      <p>
                        <img src={point331} alt="" className="w-100" />
                      </p>
                      <p>
                        <img src={point332} alt="" className="w-100" />
                      </p>
                  </div>
                  <div className="job-content">
                      <ul>
                        <li>34. Click on <strong>Transactions </strong>to validate the transactions for Accrual Scheme. </li>
                        <li>35. After that, close the window and you will be back to side window of Accrual Scheme.</li>
                        <li>36. Click on <strong>Ok </strong>to apply schedule on Invoice. </li>
                        <li>37. After clicking <strong>Ok </strong> , Side window will close and you will be back to Invoice Journal Screen. </li>
                        <li>38. Click on <strong>Validate</strong> and simulate Posting. If no error occurs, we are good to go. </li>
                        <li>39. Click on <strong>Post</strong> </li>
                        <li>40. Click on <strong>Print</strong>and from dropdown select <strong>Posted transactions by Journals to review the journal entries posted on the actual date on which we want to book the expense.</strong> </li>
                        <li>41. A new side window will open.</li>
                      </ul>
                      <p>
                        <img src={point41} alt="" className="w-100" />
                      </p>
                  </div>
                  <div className="job-content">
                      <ul>
                        <li>42. Click on <strong>Ok</strong> </li>
                      </ul>
                      <p>
                        <img src={point42} alt="" className="w-100" />
                      </p>
                  </div>
                  <div className="job-content">
                      <ul>
                        <li>43. Posted Transaction by Journal report will open where you can validate the transaction posted.</li>
                      </ul>
                      <p>
                        <img src={point43} alt="" className="w-100" />
                      </p>
                  </div>
                  <div className="job-content">
                      <p>If you see we have all the entry posted as per the expectations, It has posted Complete amount in “Prepaid Expense Account” and then on monthly basis it has calculated and posted in respective months for next 3 months.</p>
                      <p>That’s all about this blog. This feature can really make the change in your implementation. So, try to explore this and implement.</p>
                      <p>Thank you for your time!!!</p>
                      <p>I will be back next week with new blog.</p>
                      <p>Please share your feedback and also let me know if you want to pick any specific area.</p>
                      <p className="mb-0">Stay Safe and Healthy!!! </p>
                  </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
  );
}

export default accrualscheme;
