import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import net from "../../../assets/images/casestudies/net.png";
import sqlserver from "../../../assets/images/casestudies/sqlserver.png";
import windser from "../../../assets/images/casestudies/windser2008.png";
import integrationframeworkbanner from "../../../assets/images/banner/integration-framework-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";






function integrationframework() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Integration Framework' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Integration Framework | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="One of India's largest insurance companies, with a revenue of INR 1000 crores with numerous LOBs such as Home, Travel, Rural, and Commercial, each with multiple products."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/integration-framework" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Integration Framework</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={integrationframeworkbanner} alt="Integration Framework" title="Integration Framework" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>One of India's largest insurance companies, with a revenue of INR 1000 crores with numerous LOBs such as Home, Travel, Rural, and Commercial, each with multiple products. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>With several offices across India, the company has a strong centrally automated business process flow that runs from consumer engagement to policy issuance.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Individual instantiation of WCF at project level, difficult maintenance owing to modifications made in WCF separately at project level, and so on are some of the business problems. There is no common framework for structured development. On multiple servers, the same online services are hosted. and Both teams' significant development initiatives (Portal and Core System Integrations)</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Standardized development ensures that all developers use the same set of services. Any changes to the fundamental system are only reflected in IF. Simple adjustments are required of all developers. The Framework owns the data translation, not the developer. Core System services are translated into a set of common services. To speed up the process, object-based integration is used. In a scheduled process, multi-threading is used to consider many proposals in concurrently.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={windser} alt="windser2008" title="windser2008"/>
                            </div>
                            <p>Windows 2008 Server</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sqlserver} alt="sqlserver" title="sqlserver"/>
                            </div>
                            <p>SQL Server 2008</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={net} alt="net" title="net"/>
                            </div>
                            <p>	Microsoft .NET 4.0</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default integrationframework;
