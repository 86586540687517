import React, {useState} from 'react';
import Tabs from './Tabs';
import Items from './Items';
import Data from './Data';

function Gallery(){
  const [data , setData] = useState(Data);
  const categiryData = Data.map(value=>{
    return value.category
  })
  const tabsData = ['all', ...new Set(categiryData)]

  function filterCategory(category){
    if(category=='all'){
      setData(Data)
      return;
    }
    const filteredData =  Data.filter((value)=>{
      return value.category == category;
    })
    setData(filteredData);
  }
  
  return(
    <div className="container my-3 mx-100" >
      <div className="row">
        {/* <div className="col-md-1"></div> */}
        <div className="col-md-12">
          <Tabs filterCategory={filterCategory} tabsData={tabsData} />
           <Items data={data} />
        </div>
        {/* <div className="col-md-1"></div> */}
      </div>
    </div>
  )
}

export default Gallery;