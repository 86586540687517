import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import SQL from "../../../assets/images/casestudies/sql2012.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import managementreport from "../../../assets/images/casestudies/management-report.png";
import sqlserver from "../../../assets/images/casestudies/sqlserver.png";
import finance from "../../../assets/images/casestudies/finance.png";
import Manufacturingchemical from "../../../assets/images/banner/manufacturing-chemical-production.jpg";
import { Helmet } from "react-helmet";


function logisticsanddistribution() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Manufacturing and Chemical Production' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Manufacturing and Chemical Production | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Our client is a leading multinational corporation headquartered in Japan, renowned for its specialization in industrial products driven by advanced technologies in organic synthetic chemistry, polymer chemistry, and biochemistry."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/manufacturing-and-chemical-production" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Manufacturing and Chemical Production</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                <img src={Manufacturingchemical} loading="lazy" alt="Manufacturing and Chemical Production" title="Manufacturing and Chemical Production" />
                </div>                
              </Col>
            </Row>
          </Container>
      </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Our client is a leading multinational corporation headquartered in Japan, renowned for its specialization in industrial products driven by advanced technologies in organic synthetic chemistry, polymer chemistry, and biochemistry.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The client's diverse portfolio spans a wide array of industrial products, showcasing their versatility and adaptability to various sectors. From materials rooted in organic synthetic chemistry to state-of-the-art polymer applications and groundbreaking advancements in biochemistry, our client has consistently pushed the boundaries of technological possibilities.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The customer has undertaken the ambitious task of transitioning from MS Dynamics AX 2012 to D365 FO & SCM on a global scale, with the upgrade for their Mexico unit. This transition is not without its intricacies, particularly in adhering to the stringent regulations set by the Mexican government. According to the regulatory framework, every sales invoice must undergo thorough scrutiny by the government before reaching the customer. The challenge lies in ensuring that the submitted invoice contains accurate and comprehensive details of tax calculations, a recurring issue that the customer has encountered. Additionally, the generation of palletizing numbers for inventories under production has proven to be a complex hurdle that requires resolution for a seamless transition and compliance with Mexican regulatory requirements.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The integration of a robust production system allows for detailed cost analysis, enhancing transparency. Custom label layouts cater to diverse customer needs, ensuring a tailored approach. Streamlined processes enable timely invoice submission to the Mexican government, maintaining regulatory compliance. A color-coded Sales Order system quickly identifies discrepancies in unit prices and credit limits, facilitating proactive financial management. Additionally, the Quality Control process is optimized for meticulous fabric inspections, reinforcing the commitment to operational efficiency and quality assurance.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
            <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={finance} alt="finance" title="finance"/>
                            </div>
                            <p>D365 Finance & Operation</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={SQL} alt="SQL2012" title="SQL2012"/>
                            </div>
                            <p>SQL SERVER 2012</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={managementreport} alt="management-report" title="management-report"/>
                            </div>
                            <p>Management Reporter 2012</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sqlserver} alt="sqlserver" title="sqlserver"/>
                            </div>
                            <p>SQL Server</p>
                        </div>
                    </Col>
                    
                </Row>
            </div>
        </Container>
      </div>
  );
}

export default logisticsanddistribution;
