import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import ax from "../../../assets/images/casestudies/ax2012.png";
import SQL from "../../../assets/images/casestudies/sql2012.jpg";
import ssrreport from "../../../assets/images/casestudies/ssr-report.png";
import managementreport from "../../../assets/images/casestudies/management-report.png";
import autoancillarymanufacturingcompanyindiabanner from "../../../assets/images/banner/auto-ancillary-manufacturing-company-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function autoancillarymanufacturingcompany() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Auto Ancillary Manufacturing company' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Auto Ancillary Manufacturing company | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Japan based Auto Ancillary Manufacturing company want to manage their subsidiary company process which is into the manufacturer filed of power semiconductor devices, electric power systems, automotive electronics, and solenoid devices."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/auto-ancillary-manufacturing-company" />
      </Helmet>
        <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Auto Ancillary Manufacturing company</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={autoancillarymanufacturingcompanyindiabanner} alt="Auto Ancillary Manufacturing company" title="Auto Ancillary Manufacturing company" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Japan based Auto Ancillary Manufacturing company want to  manage their subsidiary company process which is into the manufacturer filed of power semiconductor devices, electric power systems, automotive electronics, and solenoid devices. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Power semiconductor devices, electric power systems, automotive electronics goods, and solenoid items are all manufactured by this firm. Power semiconductor production, power system goods, and automotive electronics products are the three main business categories for customers.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Power semiconductor production, power system goods, and automotive electronics products are the three main business segments for customers. Customer had a separated system across several locations and had significant inventory management and multi-currency reporting challenges.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>By developing application to simplify the production and planning procedures so that operations run more smoothly. Standardization of HO-aligned business processes. Streamlining data integration for foreign trade bookings, as well as the import process, as the majority of purchases are imports.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        
            <Container>
            <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={ax} alt="ax2012" title="ax2012"/>
                            </div>
                            <p>MS Dynamics AX 2012 R3</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={ssrreport} alt="ssr-report" title="ssr-report"/>
                            </div>
                            <p>SSRS Reporting</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={SQL} alt="SQL2012" title="SQL2012"/>
                            </div>
                            <p>SQL SERVER 2012 R2</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={managementreport} alt="management-report" title="management-report"/>
                            </div>
                            <p>Management Reporter</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default autoancillarymanufacturingcompany;
