import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import javascript from "../../../assets/images/casestudies/javascript.jpg";
import net from "../../../assets/images/casestudies/net.png";
import sharepoint from "../../../assets/images/casestudies/sharepoint.jpg";
import document from "../../../assets/images/casestudies/document.png";
import SQL2012 from "../../../assets/images/casestudies/sql2012.jpg";
import centralestimationbanner from "../../../assets/images/banner/central-estimation-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function centralestimation() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Central Estimation Platform' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Central Estimation Platform for Infrastructure Management Services | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="A Pioneering Fortune 100 company in the field of PC and Server Software products based in USA. In more than 70 countries throughout the world, the customer provides different infrastructure management services to its clients."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/central-estimation-platform-for-infrastructure-management-services" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Central Estimation Platform for Infrastructure Management Services</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={centralestimationbanner} alt="Central Estimation Platform" title="Central Estimation Platform" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>      
            <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>A Pioneering Fortune 100 company in the field of PC and Server Software products based in USA</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>In more than 70 countries throughout the world, the customer provides different infrastructure management services to its clients. The effectiveness of the customer's estimate procedures has been recognised as a critical area for improvement. The aim was to standardized estimating tools and procedures across geographies and give its sales force and advisors self-service estimation capabilities, even in complicated solution selling circumstances. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Today's organisations must track the efficiency of their project estimate processes and develop models that boost win rates by integrating industry and organisational operational indicators.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Estimates for self-service possibilities that span numerous service lines Improvements to the estimating engine based on trend analysis are possible. Measurement of the projected effort, cost, and schedule's level of confidence Notification to Business Heads when the Confidence Level in the estimate for a certain opportunity and the ability to execute Win/Loss Analysis pass certain criteria.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={net} alt="net" title="net"/>
                            </div>
                            <p>Microsoft .Net 4.5</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sharepoint} alt="sharepoint" title="sharepoint"/>
                            </div>
                            <p>SharePoint 2013</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={document} alt="document" title="document"/>
                            </div>
                            <p>Document Repository</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={javascript} alt="javascript" title="javascript"/>
                            </div>
                            <p>	Javascript</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={SQL2012} alt="SQL2012" title="SQL2012"/>
                            </div>
                            <p>SQL Server 2012</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default centralestimation;
