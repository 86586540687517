import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import javascript from "../../../assets/images/casestudies/javascript.jpg";
import net from "../../../assets/images/casestudies/net.png";
import ajax from "../../../assets/images/casestudies/ajax.png";
import oracle11g from "../../../assets/images/casestudies/oracle11g.png";
import insurancesolutionbanner from "../../../assets/images/banner/insurance-solution-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function insurancesolution() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Insurance Solution' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Insurance Solution | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="One of India's largest insurance companies, with a revenue of INR 1000+ crores with numerous LOBs like as Home, Travel, Rural, and Commercial, each with multiple products. With several offices across India, the company has a robust centrally controlled business process flow that runs from consumer engagement to insurance issuance."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/insurance-solution" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Insurance Solution</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={insurancesolutionbanner} alt="Insurance Solution" title="Insurance Solution" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>One of India's largest insurance companies, with a revenue of INR 1000+ crores with numerous LOBs like as Home, Travel, Rural, and Commercial, each with multiple products. With several offices across India, the company has a robust centrally controlled business process flow that runs from consumer engagement to insurance issuance.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The client is devoted to raising awareness regarding Health, Safety, Security, and the Environment due to the nature of the business. The corporation continues to make concerted efforts to raise awareness and foster an exceptional EHS culture among its stockholders' living quarters and environment.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The Portals & Collaboration practise helps clients get the most out of existing and future investments and efforts in the areas of Portals, Enterprise Content Management Systems, Enterprise Information Management Systems, Messaging and Communications, Collaboration and Social software.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Cost-effectiveness and access to the right abilities (the best of both worlds),  Existing monthly licence fees should be eliminated. Switching to the most up-to-date and proven technology in a short period of time, all while adhering to cloud standards Switching to a new programme without losing data is simple and painless. To handle outdated data workflow, advanced process workflow management with versioning is used.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={net} alt="net" title="net"/>
                            </div>
                            <p>Microsoft .Net 4.5 Framework</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={javascript} alt="javascript" title="javascript"/>
                            </div>
                            <p>	Javascript</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={ajax} alt="ajax" title="ajax"/>
                            </div>
                            <p>AJAX</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={oracle11g} alt="oracle11g" title="oracle11g"/>
                            </div>
                            <p>Oracle 11g 64bit</p>
                        </div>
                    </Col>
                </Row>
            </div>
            </Container>
      </div>
  );
}

export default insurancesolution;
