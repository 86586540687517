import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import WCFServices from "../../../assets/images/casestudies/wcf-services.png";
import Net from "../../../assets/images/casestudies/net.png";
import jquery from "../../../assets/images/casestudies/jquery.png";
import mysqlserver from "../../../assets/images/casestudies/mysqlserver.png";
import IIS from "../../../assets/images/casestudies/iis.png";
import htmlcss from "../../../assets/images/casestudies/htmlcss.png";
import engineeringandtechnologybanner from "../../../assets/images/banner/engineering-and-technology-solution-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function engineeringandtechnology() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Engineering and technology' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Engineering and technology solution provider that provides goods and services | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Client is an engineering and technology solution provider that provides goods and services to improve the energy and operational efficiency of buildings, car batteries, electronics, and automobile interior systems."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/engineering-and-technology-solution-provider-that-provides-goods-and-services" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Engineering and technology solution provider that provides goods and services</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={engineeringandtechnologybanner} alt="Engineering and technology" title="Engineering and technology" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Client is an engineering and technology solution provider that provides goods and services to improve the energy and operational efficiency of buildings, car batteries, electronics, and automobile interior systems.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Client is a supplier of engineering and technology solutions that increase the energy and operational efficiency of buildings, vehicle batteries, electronics, and automobile interior systems. With activities in six countries, it is a Fortune 500 Diversified, Multi-Industrial, Multi-Continental Corporation. It provides building control engineering services such as HVAC, fire and security, energy management, and facility management.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>It used to take a sales person a long time to create Service proposal paperwork. This makes it impossible for the client to deliver effective costing, as well as the inability to monitor performance in the lack of any analytical data. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Automated Proposal Generation, Proposal Generation Time Reduced from 2 Days to 2 Hours, Support is available in a variety of languages throughout the world. Sales Force integration, Manual labour costs have been reduced. improved client service, Better and more interactive reports that can be delved down for more information, Analytical data was used to identify profit margin insights, and vulnerability was avoided by adhering to tight security requirements.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={Net} alt="Net" title="Net"/>
                            </div>
                            <p>C#, ASP.NET MVC</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={jquery} alt="jquery" title="jquery"/>
                            </div>
                            <p>jQuery, Bootstrap</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={mysqlserver} alt="mysqlserver" title="mysqlserver"/>
                            </div>
                            <p>SQL SERVER</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={WCFServices} alt="WCF-Services" title="WCF-Services"/>
                            </div>
                            <p>	WCF Services </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={IIS} alt="IIS" title="IIS"/>
                            </div>
                            <p>IIS7.5/8.0</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={htmlcss} alt="htmlcss" title="htmlcss"/>
                            </div>
                            <p>HTML5/CSS3</p>
                        </div>
                    </Col>
                </Row>
            </div>
            </Container>
      </div>
  );
}

export default engineeringandtechnology;
