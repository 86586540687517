import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import ax2012 from "../../../assets/images/casestudies/ax2012.png";
import SQL from "../../../assets/images/casestudies/sql2012.jpg";
import ssrreport from "../../../assets/images/casestudies/ssr-report.png";
import autoancillarymanufacturingcompanyindiabanner from "../../../assets/images/banner/auto-ancillary-manufacturing-company-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";


function autoancillarymanufacturingcompanyindia() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Auto Ancillary Manufacturing company - India' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Auto Ancillary Manufacturing company - India | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Customer, founded in 1972 and with decades of experience in foundry and automotive clutch components, is one of India's top manufacturers and suppliers of clutch components, serving both domestic and foreign OEMs."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/auto-ancillary-manufacturing-company-india" />
      </Helmet>
        <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Auto Ancillary Manufacturing company - Indi a </h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={autoancillarymanufacturingcompanyindiabanner} alt="Auto Ancillary Manufacturing company India" title="Auto Ancillary Manufacturing company India" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Customer, founded in 1972 and with decades of experience in foundry and automotive clutch components, is one of India's top manufacturers and suppliers of clutch components, serving both domestic and foreign OEMs.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Top motor vehicle manufacturers in India, the United States, and the United Kingdom employ the manufactured parts in their vehicles. Customer desired to implement a world-class ERP to address pain points such as Inventory Planning & MRP; Cost & Consumption Tracking; Generation of Supplementary Invoices for price changes for items for which the Sales Invoice had already been processed; and Calculation of Forex Gain / Loss in export sales, as accurate exchange rates are not captured while booking transactions.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Free Text Invoice customised to generate supplementary invoice without inventory effect, Single click option to select invoices to be included in Supplementary Invoice, and Online integration Currency Rate with AX to calculate the accurate exchange rate while booking the transaction are the challenging aspects.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>On the Supplementary Invoice, time is saved. Customers are automatically notified of pending invoices through email. Invoicing procedure and documentation must be followed correctly. In export transactions, accurate profit and loss are calculated for so that transaction booking may be done where the most profit can be made. The job of the collection department becomes more precise, and effective tracking of past-due invoices is carried out. Calculation of Forex Loss and Gain based on the current exchange rate.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
            <Container>
            <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={ax2012} alt="ax2012" title="ax2012"/>
                            </div>
                            <p>MS Dynamics AX 2012 R3</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={SQL} alt="SQL" title="SQL"/>
                            </div>
                            <p>SQL SERVER 2012 R2</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={ssrreport} alt="ssr-report" title="ssr-report"/>
                            </div>
                            <p>	SSRS Reporting</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default autoancillarymanufacturingcompanyindia;
