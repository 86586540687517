import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import Net from "../../../assets/images/casestudies/net.png";
import angular from "../../../assets/images/casestudies/angular6.jpg";
import mvc from "../../../assets/images/casestudies/mvc.png";
import webapi from "../../../assets/images/casestudies/webapi.png";
import oracle from "../../../assets/images/casestudies/oracle.png";
import realtimepolicybanner from "../../../assets/images/banner/policy-issuance-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";



function realtimepolicy() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Online Real-time Policy Issuance Engine Portal' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Online Real-time Policy Issuance Engine Portal | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="One of India's largest insurance companies, with a revenue of over 1000 crores with numerous LOBs including as Home, Travel, Rural, and Commercial, each with multiple products."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/online-real-time-policy-issuance-engine-portal" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Online Real-time Policy Issuance Engine Portal</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={realtimepolicybanner} alt="Online Real-time Policy Issuance Engine Portal" title="Online Real-time Policy Issuance Engine Portal" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>One of India's largest insurance companies, with a revenue of over 1000 crores with numerous LOBs including as Home, Travel, Rural, and Commercial, each with multiple products. With several offices across India, the company has a strong centrally automated business process flow that runs from consumer engagement to policy issuance.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>A purpose online web application platform that allows all intermediaries and agents to use and issue policies in real time with the backend system. The goal of this portal is to give a mobile-friendly URL for on-the-go customer engagement and to display the many cover alternatives available. Along with the data warehouse, more than 20 integrations have been accomplished.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>A purpose online web application platform that allows all intermediaries and agents to use and issue policies in real time with the backend system. The goal of this portal is to give a mobile-friendly URL for on-the-go customer engagement and to display the many cover alternatives available. Along with the data warehouse, more than 20 integrations have been accomplished.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Gather and integrate data from a variety of sources, as well as input from implementers, partners, and beneficiaries, to identify and investigate impediments to successful policy implementation. Identify solutions to problems and push for action to be taken. Maintain mechanisms to allow for frequent evaluations of implementation results. Share best practises among organisations, and incorporate lessons learned into future policies and strategies.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={Net} alt="Dot-Net" title="Dot-Net"/>
                            </div>
                            <p>.Net 4.6</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={mvc} alt="mvc" title="mvc"/>
                            </div>
                            <p>ASP.NET MVC 5.0</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={webapi} alt="SQwebapi" title="webapi"/>
                            </div>
                            <p>WebAPI</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={angular} alt="angular6" title="angular6"/>
                            </div>
                            <p>Angular JS</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={oracle} alt="Oracle 12c" title="Oracle 12c"/>
                            </div>
                            <p>Oracle 12c</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default realtimepolicy;
