import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import vs from "../../../assets/images/casestudies/vs.png";
import aspnet from "../../../assets/images/casestudies/asp.net.png";
import mvc from "../../../assets/images/casestudies/mvc.png";
import IIS from "../../../assets/images/casestudies/iis.png";
import jquery from "../../../assets/images/casestudies/jquery.png";
import htmlcss from "../../../assets/images/casestudies/htmlcss.png";
import coursedeliverysystembanner from "../../../assets/images/banner/course-delivery-system-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";




function coursedeliverysystem() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Course Delivery System' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Course Delivery System | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Employees of educational institutions, commercial enterprises, and government organisations in the United States are trained through online compliance training courses provided by the client. Our client has been using online compliance training to assist firms all around the world establish ethical, inclusive, and safe workplaces since 1997"
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/course-delivery-system" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Course Delivery System</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={coursedeliverysystembanner} alt="Course Delivery System" title="Course Delivery System" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Employees of educational institutions, commercial enterprises, and government organisations in the United States are trained through online compliance training courses provided by the client. Our client has been using online compliance training to assist firms all around the world establish ethical, inclusive, and safe workplaces since 1997</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Discrimination prevention and Sexual harassment, diversity awareness and inclusion, uncovering implicit bias, lawful hiring, data security and privacy, workforce management, campus compliance, ethics, wage and hour, performance management and hiring, safety and violence prevention, and learning management systems are among the topics covered by the company's online compliance training courses for supervisors and employers. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Individual course content includes huge graphics, flash, and video files (MP4), among other things. The particular course is about 100 pages long, and the customer expects 15,000 concurrent users in the following years. Although the old content delivery system was functional, its scalability was limited. Our client also has a number of IT applications that assist with operations, administration, and content distribution.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The Course Delivery System was subjected to load testing with a goal of loading the system with 10,000 users. However, the existing programme could not handle a load of 1000 users. We made recommendations based on the load test findings to fix performance bottlenecks and increase the system's ability to serve a larger number of users at the same time.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={vs} alt="vs" title="vs"/>
                            </div>
                            <p>	Visual Studio 2013</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={aspnet} alt="asp.net" title="asp.net"/>
                            </div>
                            <p>C#, .NET 4.5</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={mvc} alt="mvc" title="mvc"/>
                            </div>
                            <p>ASP.NET MVC 5</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={IIS} alt="IIS" title="IIS"/>
                            </div>
                            <p>IIS 7.5/8.0</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={jquery} alt="jquery" title="jquery"/>
                            </div>
                            <p>Jquery & AJAX</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={htmlcss} alt="htmlcss" title="htmlcss"/>
                            </div>
                            <p>	HTML 5 / CSS3</p>
                        </div>
                    </Col>
                </Row>
            </div>
            </Container>
      </div>
  );
}

export default coursedeliverysystem;
