import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./imageModal.css";

function ImageModal({ show, onHide, images, selectedImageIndex }) {
  const [currentIndex, setCurrentIndex] = useState(selectedImageIndex);

  const galleryImages = images.map((image, index) => ({
    original: image,
    thumbnail: image,
    description: `Image ${index + 1}`,
  }));

  useEffect(() => {
    // Update the currentIndex when the selectedImageIndex changes
    setCurrentIndex(selectedImageIndex);
  }, [selectedImageIndex]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      ClassName="larger-modal"
      closeButton
    >
      

      <div className="custom-gallery-container">
        <Gallery
          items={galleryImages}
          startIndex={currentIndex}
          showThumbnails={false}
          showFullscreenButton={true}
          showPlayButton={false}
        />
      </div>
    </Modal>
  );
}

export default ImageModal;
