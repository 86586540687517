import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import androidjava from "../../../assets/images/casestudies/androidjava.jpg";
import iosswift from "../../../assets/images/casestudies/iosswift.png";
import sqlite from "../../../assets/images/casestudies/sqlite.png";
import claimmanagementinsurancebanner from "../../../assets/images/banner/claim-management-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function claimmanagementinsurance() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Claim management (Insurance)' },
    
      ];
  return (
      <div className="insurance">.
      <Helmet>
        <title>
        Claim management (Insurance) | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="The customer, who was situated in India, needed to create a claim management mobile application to handle and settle all claims."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/claim-management-insurance" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Claim management (Insurance)</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={claimmanagementinsurancebanner} alt="Claim management (Insurance)" title="Claim management (Insurance)" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The customer, who was situated in India, needed to create a claim management mobile application to handle and settle all claims.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>A formal request to an insurance company for payment based on the provisions of the insurance policy is called an insurance claim. Once the claim is authorised, the insurance company examines it for legitimacy before paying the insured or the requesting party (on behalf of the insured).</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Claim processing necessitates a significant amount of time, money, and people, especially when data must be collected from many sources and supplied into a system for processing. This was the problem we solved by designing the solution in Android as a mobile application enabling surveyors to record all claim processing data and input it straight into the system for claim acceptance at the next level. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The time it took to process a claim was dramatically decreased. The expense of travel has been cut in half. The claim stage is kept up to date for all parties concerned. Customers can receive tracking information by WhatsApp, email, or phone. Customers' personal information is kept safe.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={iosswift} alt="iosswift" title="iosswift"/>
                            </div>
                            <p>iOS-Swift 5.0 </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={androidjava} alt="androidjava" title="androidjava"/>
                            </div>
                            <p>Android-Java </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sqlite} alt="sqlite" title="sqlite"/>
                            </div>
                            <p>	SQLite</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default claimmanagementinsurance;
