import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import "./lifeatsynoverge.scss";
import Gallery from "../../component/life-synoverge/Gallery";
import "../../component/pages/pages.scss";
import lifeatsynovergebanner from "../../assets/images/banner/lifeatsynoverge-banner.jpg";
import refer from "../../assets/images/animated-icons/refer.gif";
import userprofile from "../../assets/images/animated-icons/user-profile.gif";
import jobinterview from "../../assets/images/animated-icons/job-interview.gif";
import certificate from "../../assets/images/animated-icons/certificate.gif";
import target from "../../assets/images/animated-icons/target.gif";
import CustomBreadcrumb from '../BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";


function lifeatsynoverge() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'About Synoverge',link:'/our-story' },
        { label: 'Life At Synoverge' },
      ];

    return (
        <div className="life-synoverge pt-80">
             <Helmet>
        <title>
        Life at Synoverge | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="
          Learn About Us. Synoverge is a space where diverse individuals collaborate as a single team, express their creativity, and produce outcomes."
        />
        <link rel="canonical"href="https://www.synoverge.com/life-at-synoverge" />
      </Helmet>
            <div className="banner">
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className="banner-cont">
                                <h1>Life At Synoverge</h1>
                                {/* <h1>Exciting events and activities, keep lethargy and boring at bay</h1> */}
                                <p>Happy employees are healthier. Having fun improves communication and collaboration. Period.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="banner-img">
                                <img src={lifeatsynovergebanner} alt="leadership-Team" title="leadership-Team" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="module">
                <Container>
                <CustomBreadcrumb items={breadcrumbItems} />
                    
                    <Row>
                    <h3>Our Workplace Culture</h3>
                        <Col md={12}>
                            <Gallery />
                        </Col>
                    </Row>
                </Container>
                
            </div>
            <div className="module module-gray">
                <Container>
                    <Row>
                        <Col md={12}>
                            <h3>Reasons to work with us</h3>
                        </Col>
                        <Col md={12}>
                            <div>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Our Values</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Our values focuses on various key factors such transparency, work excellence, quality commitment to our customers.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Leadership</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Our leadership team encourages and fosters the transparent work culture wherein anyone can walk to their place at any point in time for sharing any concern/issue/suggestions.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Work Environment</Accordion.Header>
                                        <Accordion.Body>
                                            <p>We don't believe in typical white washed formal environment. We have our lively & vibrant offices promoting creativity & comfort for our employees during their work hours at office.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Team Diversity</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Nothing could be better than learning from your peers. We at Synoverge ensure that work people are from different backgrounds and different walks of life. Bringing along with them a rich & diverse experience on the table.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Employee First Approach</Accordion.Header>
                                        <Accordion.Body>
                                            <p>We value our employee & make sure that they have a fruitful time to work. A time which could enhance to their learning, experience & skill-sets.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>Career Growth</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Synoverge believes in providing an equal platform for all, to display their talent & build their career with us. We believe in growing together.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>Innovation and Learning</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Synoverge encourage its employees to hone their skill-set, promoting active learning to keep abreast with the trending technologies and bring in innovation.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>Flexible work hours</Accordion.Header>
                                        <Accordion.Body>
                                            <p>We understand that 9 to 5 may not always fit into everyone's schema. Therefore we offer flexible timings for our employees to attain a perfect work life balance. We believe in smart work & result driven approach. We don't encourage our employees to slog for long hours.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>Focussed Mentorship & Support</Accordion.Header>
                                        <Accordion.Body>
                                            <p>We honor great attitude & a zeal to learn, unlearn & continue the process. Our management team ensures a strategically designed mentorship program for all employees.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="9">
                                        <Accordion.Header>Fast Track Program</Accordion.Header>
                                        <Accordion.Body>
                                            <p>There is an exclusive fast track program for potential employees to grow faster in hierarchy and take up higher responsibilities at early stages of career .</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="module">
                <Container>
                    <Row>
                        <Col md={12}>
                            <h3>Tips to become part of Synoverge</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} lg={2} className="d-inline-flex">
                            <div className="syno-card">
                                <p>
                                    <img
                                        src={refer}
                                        alt="refer"
                                        className="w-25"
                                    />
                                </p>
                                <h4>Get <br></br> Referred</h4>
                                <p>Browse your network and look for acquaintances who are working with Synoverge or those who have worked with us in past. Take the help of those people to get your profile referred for the current openings and consideration. This helps to approach with proven credibility and thereby firmness to make a right choice.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={2} className="d-inline-flex">
                            <div className="syno-card">
                                <p>
                                    <img
                                        src={userprofile}
                                        alt="user-profile"
                                        title="user-profile"
                                        className="w-25"
                                    />
                                </p>
                                <h4>Share your Profile with us</h4>
                                <p>You can directly mail your updated curriculum vitae on <Link to="mailto:career@synoverge.com">career@synoverge.com</Link> or apply against the open vacancy on career page in About Synoverge section with subject line mentioning the Title of position applied for and your total years of experience. </p>
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={2} className="d-inline-flex">
                            <div className="syno-card">
                                <p>
                                    <img
                                        src={jobinterview}
                                        alt="job-interview"
                                        title="job-interview"
                                        className="w-25"
                                    />
                                </p>
                                <h4>Preparedness for Interview</h4>
                                <p>Adherence to the requisite professional etiquettes for attending interview will be the key here. Gathering basic understanding about the Company, its verticals and functions will help appear for interview with more confidence.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={2} className="d-inline-flex">
                            <div className="syno-card">
                                <p>
                                    <img
                                        src={certificate}
                                        alt="certificate"
                                        title="certificate"
                                        className="w-25"
                                    />
                                </p>
                                <h4>Be Professional & Honest</h4>
                                <p>Honesty and Integrity towards representation of the skills and experience is an essential identity of a professional for long term association.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={2} className="d-inline-flex">
                            <div className="syno-card">
                                <p>
                                    <img
                                        src={target}
                                        alt="target"
                                        title="target"
                                        className="w-25"
                                    />
                                </p>
                                <h4>Bring The <br></br> Real You</h4>
                                <p>Showcase your personality throughout the interview and be open and honest about your ambitions, passions, and objectives. This will allow the interviewer to get a better understanding of you on a personal level. We're looking for somebody who brings life to the organization.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default lifeatsynoverge;

