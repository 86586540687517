import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import managementreport from "../../../assets/images/casestudies/management-report.png";
import finance from "../../../assets/images/casestudies/finance.png";
import azure from "../../../assets/images/casestudies/azure.png";
import access from "../../../assets/images/casestudies/access.jpg";
import Manufacturingbanner from "../../../assets/images/banner/manufacturing.jpg";
import { Helmet } from "react-helmet";

function logisticsanddistribution() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Manufacturing' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Manufacturing | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Our client is a multinational corporation headquartered in Japan, recognized for its specialization in a diverse range of industries. With a primary focus on iron and steel, welding, aluminium and copper, machinery, engineering, construction machinery, and electric power, the client has established itself as a key player in various sectors, showcasing a broad spectrum of expertise."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/manufacturing" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Manufacturing</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                <img src={Manufacturingbanner} loading="lazy" alt="Manufacturing" title="Manufacturing" />
                </div>                
              </Col>
            </Row>
          </Container>
      </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Our client is a multinational corporation headquartered in Japan, recognized for its specialization in a diverse range of industries. With a primary focus on iron and steel, welding, aluminium and copper, machinery, engineering, construction machinery, and electric power, the client has established itself as a key player in various sectors, showcasing a broad spectrum of expertise.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The multinational corporation, headquartered in Japan, operates across multiple industries with a distinctive focus on iron and steel, welding, aluminium and copper, machinery, engineering, construction machinery, and electric power. This diversified business portfolio positions the company as a significant player with a comprehensive range of offerings and a global impact.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Facing the impending end of Microsoft support for AX 2012 in December 2022, the customer confronts the formidable task of upgrading to MS Dynamics 365 FO. The additional challenge lies in opting for a Re-Implementation approach for the FO upgrade, necessitating modifications to existing business processes from AX within FO, wherever required. Furthermore, selected customizations must be recreated in FO from the ground up, adding complexity to the upgrade process.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The migration to D365 results in streamlined business processes, integrating selected customizations and allowing for the incorporation of new ones. The upgrade enables the generation of diverse Positive Pay and ACH files, catering to multiple banks and payment methods. Additionally, module duplication with a defined prefix enhances security roles, fortifying system integrity within D365. These improvements collectively contribute to a more efficient, adaptable, and secure operational environment.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
            <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={finance} alt="finance" title="finance"/>
                            </div>
                            <p>D365 Finance & Operation</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={managementreport} alt="management-report" title="management-report"/>
                            </div>
                            <p>Management Reporter 2012</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={azure} alt="azure" title="azure"/>
                            </div>
                            <p>Microsoft Azure</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={access} alt="Access" title="Access"/>
                            </div>
                            <p>Access</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
      </div>
  );
}

export default logisticsanddistribution;
