import React from 'react';
import "../life-synoverge/life-synoverge.scss";
function Tabs({ filterCategory, tabsData }) {
  return (
    <>
      {tabsData.map((category, index) => {
        return (
          <>
            <button
              onClick={() => filterCategory(category)}
              type="button"
              className="btn btn-primary ms-0 me-3 text-capitalize filter-btn"
              key={index}
            > {category}
            </button>
          </>
        );
      })}
    </>
  );
}

export default Tabs;
