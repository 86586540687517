import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import iosswift from "../../../assets/images/casestudies/iosswift.png";
import androidjava from "../../../assets/images/casestudies/androidjava.jpg";
import PHP from "../../../assets/images/casestudies/php.jpg";
import beanstalk from "../../../assets/images/casestudies/beanstalk.png";
import smartwaterfiltersbanner from "../../../assets/images/banner/smart-water-filters-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function smartwaterfilters() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Smart Water Filters' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Smart Water Filters | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Customer is from India and has worked in the water industry for over 20 years. They are a market leader in industrial and commercial water filtration and provide a wide range of water filters."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/smart-water-filters" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Smart Water Filters</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={smartwaterfiltersbanner} alt="Smart Water Filters" title="Smart Water Filters" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Customer is from India and has worked in the water industry for over 20 years. They are a market leader in industrial and commercial water filtration and provide a wide range of water filters.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>They are driving in the modern and business water cleansing and giving different sorts of water channel. Their point is to help in further developing the water quality and give the better health. The significance of this initiative in terms of human health depends on excellent water.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The customer's challenge was to reach out to consumers in a more effective manner, give multiple data points to monitor water quality, and find a means to quickly resolve any difficulties that a customer could have.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Visitors can now see the filtering process on their app's dashboard, and they may simply contact the support staff with any questions or issues and receive a timely response. With AWS, the solution was auto-scalable, and historic data was kept for machine learning and future implementation, as well as real-time data, which provided the most up-to-date filter data. </p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={iosswift} alt="iosswift" title="iosswift"/>
                            </div>
                            <p>iOS- Swift</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={androidjava} alt="androidjava" title="androidjava"/>
                            </div>
                            <p>Android - Java</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={PHP} alt="PHP" title="PHP"/>
                            </div>
                            <p>PHP</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={beanstalk} alt="beanstalk" title="beanstalk"/>
                            </div>
                            <p>BeanStalk </p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default smartwaterfilters;
