import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import dynamics from "../../../assets/images/casestudies/dynamics-365.png";
import Vonage from "../../../assets/images/casestudies/vonage.jpg";
import linkedin from "../../../assets/images/casestudies/linkedin.png";
import insurancebanner from "../../../assets/images/banner/insurance-e-bonding-processe-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";


function insurance() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Insurance & e-Bonding process' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Insurance & e-Bonding process | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="USA based client is an electronic guarantee bond handling framework that empowers customers, merchants/specialists, obliges, and transporters to deal with their guarantee bond prerequisites effectively."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/insurance-e-bonding-process" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Insurance & e-Bonding process</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={insurancebanner} alt="Insurance & e-Bonding process" title="Insurance & e-Bonding process" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>USA based client is an electronic guarantee bond handling framework that empowers customers, merchants/specialists, obliges, and transporters to deal with their guarantee bond prerequisites effectively. They are having the tremendous information of clients and organizations in to dominate for catering their current CRM processes.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The project is an electronic guarantee bond handling framework that allows clients, merchants/specialists, and carriers to efficiently manage their guarantee bond requirements. They have a wealth of knowledge about clients and businesses that they may use to improve their present CRM operations.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>They have a large amount of customer and company data in excel that they use to support their present CRM procedures. This results in missed opportunities to follow up with leads and prospects, as well as unanswered calls and contract close delays. Aside from that, there is a duplication in contract and account data management. Another issue that customers have is that document and knowledge assets are not kept in a common repository. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The project result is smoothing out of key cycles for smoother operations, Coordination of CRM Contacts with LinkedIn Sales Navigator for simplicity of lead management, Joining of Vonage with Contacts and Accounts for smoother case the board and call tracking, Incorporated business process from Lead to Invoice and Focal record storehouse which diminishes the duplication.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={dynamics} alt="dynamics-365" title="dynamics-365"/>
                            </div>
                            <p>Dynamics 365 for Sales</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={Vonage} alt="Vonage" title="Vonage"/>
                            </div>
                            <p>Vonage</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={linkedin} alt="linkedin" title="linkedin"/>
                            </div>
                            <p>LinkedIn Sales Navigator</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default insurance;
