import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "../pages/pages.scss";
import projectmethodologybanner from "../../assets/images/blog/project-mathodology.jpg";
import CustomBreadcrumb from '../BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";

function projectmethodologyagile() {
  const breadcrumbItems = [
    { label: 'Home', link: '/' },
    { label: 'Blog', link:'/blog' },
    { label: 'Project Methodology' },
  ];
  return (
    <Container fluid className="px-0">
      <div className="blog-content">
      <Helmet>
        <title>
        Project Methodology | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Agile methodologies have been around for over two decades and have not only changed the way we deliver software but also evolved multi-fold over these two decades.  There are many variants of Agile methodologies being deployed by various organizations to meet specific requirements or to scale."
        />
        <link rel="canonical" href="https://www.synoverge.com/blog/project-methodology-agile" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Project Methodology</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={projectmethodologybanner} alt="ourstory" title="ourstory" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <div className="module">
          <Container>
          <CustomBreadcrumb items={breadcrumbItems} />
              <Row>
                <Col md={12}>
                    <div className="job-content">
                        <h3>Project Methodology</h3>
                        <p> <strong>Disciplined Agile Delivery (DAD) – Choose your WoW </strong> </p>
                        <ul>
                          <li>Agile methodologies have been around for over two decades and have not only changed the way we deliver software but also evolved multi-fold over these two decades.  There are many variants of Agile methodologies being deployed by various organizations to meet specific requirements or to scale.</li>
                          <li>Here we will be discussing an Agile Methodology promoted by Project Management Institute (PMI) - a Disciplined Agile Delivery (DAD). It strongly believes “True business agility comes from freedom, not frameworks”.</li>
                          <li>Disciplined Agile is a people first, learning oriented hybrid agile approach to IT Solution Delivery. It provides a tool kit that harnesses hundreds of Agile practices to guide you to the best Way of Working (WoW) for your team or organization. It has a risk value delivery life cycle, is goal driven, enterprise aware and scalable.</li>
                        </ul>
                        <p> <strong>Principles of Disciplined Agile Delivery (DAD)</strong> </p>
                        <ul>
                          <li>
                            <strong>Delight Customer</strong>
                            <ul>
                              <li>Agile Manifesto states <strong> “Our highest priority is to satisfy the customer through early and continuous delivery of valuable software”.</strong> </li>
                              <li>DAD takes this a step further from customer satisfaction to customer delight. How does one delight a customer? To delight a customer we need to not just fulfill the customer expectations but also surpass them.</li>
                              <li>Successful organizations deliver great services and products by building products and services with  <strong>customers in mind</strong>, working with them closely, delivering small regular increments of working software, and following it up with regular feedback to deliver what will actually delight the customer. </li>
                              <li>Such organizations <strong> embrace change</strong>  knowing well that stakeholders will change their minds as they learn what they truly want as they see the solution evolving.</li>
                            </ul>
                          </li>
                          <li>
                            <strong>Be Awesome</strong>
                            Who doesn’t want to be part of an awesome team or an awesome organization. Awesome employees make awesome teams and awesome teams build awesome deliverables and build an awesome organization. What are the attributes of awesome employees?
                            <ul>
                              <li>Awesome employees act in a way that earns respect and trust of colleagues – <strong> be reliable, be honest, be open and treat others with respect</strong> </li>
                              <li>Awesome employees willingly collaborate with other team members by  <strong> sharing information, offering help, asking for help, active listening and participating</strong> </li>
                              <li>Awesome employees are <strong> active learners </strong> who are keen on mastering specialist skills and always look for opportunities to  <strong> expand knowledge beyond their area of specialization</strong>  which allows them to better understand other team members and interact effectively with them </li>
                              <li>Awesome employees focus on <strong> improving and managing emotional responses</strong> to difficult situations as innovation requires diversity and diversity can lead to difficult situations <br/>Awesome teams are built by team of  <strong> awesome motivated employees</strong> with <strong> desire to excel</strong>  who operate in environment where team members can depend on each other, have good work relationships with team members and different stakeholders that enables collaboration.  Awesome teams <strong> focus on quality from very beginning</strong>  – doing things right the first time.</li>
                            </ul>
                          </li>
                          <li> <strong>Context Counts & Being Pragmatic</strong> 
                            <ul>
                              <li>Every individual is unique with different skill, work style, goals and learning style. Each team is also unique as it comprises of unique individuals and is facing a unique project situation operating in a unique organization. Hence the team must tailor its processes as per its unique team skills, team size, culture, nature of project, complexity, domain, customer expectations, etc.</li>
                              <li>DAD prefers pragmatism over prescription. It promotes being as effective as a team can be, given the project situations by choosing the right processes and procedures that are best suited to the project context for achieving its objectives. Disciplined agile offers various strategies too choose from based on the project context. Team optimizes its processes continuously to improve its performance.</li>
                            </ul>
                          </li>
                          <li> <strong>Enterprise Awareness</strong> 
                            <ul>
                              <li>Giving importance to overall organization needs and focus on project outcomes that contribute to larger goals rather than just individual or project goals, by working with other departments, leveraging company assets, following organization standards, sharing learning across teams, inspire reciprocity through transparency.</li>
                            </ul>
                          </li>
                          <li> <strong>Optimize Flow</strong>DAD uses lean principles to optimize the overall flow.
                              <ul>
                                <li> <strong>Deliver value continuously at sustainable pace</strong> - by committing to deliver work planned based on team capacity and velocity. </li>
                                <li> <strong>Optimize the whole</strong>  - recognizes that each team is operating in a larger context of organization and should do what is best for organization at large not just what is convenient to them. </li>
                                <li> <strong>Make work flow</strong>  - encourages team to visualize work to produce smooth delivery flow and minimize work in progress by resolving impediments / bottlenecks to flow quickly. </li>
                                <li> <strong>Eliminate waste</strong>  focuses team’s energy on activities that add value to the finished product and eliminate activities that are non-value adding like wait times, unnecessary artefacts, fixing defects, etc. </li>
                                <li> <strong>Improve continuously</strong>  - promotes culture of continuous improvement including sharing of knowledge, skill upgrade, reflecting upon how they can perform better and making necessary adjustments. It effectively encourages team to modify approach based on experience. </li>
                                <li> <strong>Experiment to learn</strong>  - promotes experimentation mindset which speeds up continuous learning process. Experimentation is encouraged and rewarded. </li>
                                <li> <strong>Measure what counts</strong>  - recommends team to identify its improvement priorities and measure performance in these areas to assess improvement initiatives are working or not. </li>
                                <li> <strong>Prefer long lived stable teams</strong>  - advises long lived stable product team over short lived project teams that are dissolved when project is over and reformed when new requirements are received in the project. </li>
                              </ul>
                           </li>
                           <li> <strong>Other lean governance principles promoted by DAD</strong> 
                              <ul>
                                <li>Collaboration over conformance</li>
                                <li>Enablement over inspection</li>
                                <li>Continuous monitoring over quality gates / milestone review</li>
                                <li>Transparency over management reporting</li>
                              </ul>
                           </li>
                        </ul>
                        <p className="mb-0">Now that you have a brief introduction of the strong principles of DAD, are you excited to learn more about it and explore how this can be applied to your project? To know more and further reference – refer source <Link to="https://www.pmi.org/" target="blank">www.pmi.org</Link></p>
                    </div>
                </Col>
              </Row>
          </Container>
        </div>
      </div>
    </Container>
  );
}

export default projectmethodologyagile;
