import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import finance from "../../../assets/images/casestudies/finance.png";
import msdynamics from "../../../assets/images/casestudies/msdynamics.png";
import homewarrantybanner from "../../../assets/images/banner/home-warranty-services-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function homewarrantyservices() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Home Warranty Services - F&O' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Home Warranty Services - F&O | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Client is a Virginia based company into new home warranty servies and provider of innovative products and exceptional customer service in the United States."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/home-warranty-services-fo" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Home Warranty Services - F&O</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={homewarrantybanner} alt="home-warranty-services" title="home-warranty-services" />
                </div>                
              </Col>
            </Row>
          </Container>
      </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Client  is a Virginia based company into new home warranty servies and provider of innovative products and exceptional customer service in the United States.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The Company was founded in the United States 25 years ago, with the goal of developing ground-breaking risk management solutions to solve the complexities that America's new home builders face. The company's evolution began with a focus on warranty administration services and has progressed to its current state. Client is committed to becoming a critical risk management partner in the new home construction industry.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The key challenge is to integrate their product enrolment data with a sophisticated Finance application so that billing, including accounting and salesperson commission computation, can be done immediately on each registration. On the other hand, the systems they were using for AP, AR, GL, Cash, and Bank were from various platforms, and most of them were unable to provide timely solutions for decision making and operation execution because the data was stored in separate silos.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>We created a comprehensive mapping of business processes and integrated it with a D-365 Sales & FO applications to automate the customer, sales, salesperson commission, and taxation procedures. As a result, auto recording of end-to-end sales transactions, including customer receipts, is becoming more seamless than before, reducing human errors and streamlining business operations.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={finance} alt="finance" title="finance"/>
                            </div>
                            <p>Microsoft Dynamics D365 Finance & Operation</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={msdynamics} alt="msdynamics" title="msdynamics"/>
                            </div>
                            <p>SQL Server 2008</p>
                        </div>
                    </Col>
                </Row>
            </div>
            </Container>
      </div>
  );
}

export default homewarrantyservices;
