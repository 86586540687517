import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import BC from "../../../assets/images/casestudies/business-central.jpg";
import SQL from "../../../assets/images/casestudies/sql2012.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import Financeservices from "../../../assets/images/banner/finance-services.jpg";
import { Helmet } from "react-helmet";

function logisticsanddistribution() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Financial Services' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Financial Services | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="With over 25 years in the Indian market, our client stands as a notable BSE-listed and RBI Registered NBFC. Specializing in MSME lending, their tailored products effectively bridge the credit gap for entrepreneurs, employing technology to streamline the loan process seamlessly."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/financial-services" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Financial Services</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                <img src={Financeservices} loading="lazy" alt="Financial Services" title="Financial Services" />
                </div>                
              </Col>
            </Row>
          </Container>
      </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>With over 25 years in the Indian market, our client stands as a notable BSE-listed and RBI Registered NBFC. Specializing in MSME lending, their tailored products effectively bridge the credit gap for entrepreneurs, employing technology to streamline the loan process seamlessly.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>More than just numbers, our client is committed to creating a positive impact by delivering timely, cost-effective credit to approximately 500 million underserved borrowers. Having successfully disbursed over 160,000 loans, our client isn't merely a lender but a potent catalyst for meaningful economic empowerment, actively supporting the growth aspirations of small businesses across India.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Our client, an NBFC, is actively involved in offering Gold Loans and Traditional Loans. To elevate their loan management process, they are embarking on the implementation of an automation system. This system is designed to streamline and simplify a range of financial activities, encompassing loans, EMIs, penalty charges, and disbursements. The core focus is on achieving an automated general journal line creation and accurate posting in the financial records. This strategic move aims to enhance efficiency and precision in managing various aspects of their lending operations.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The implementation of streamlined loan processes results in significantly reduced processing times, facilitating faster approvals and disbursements. The automated system plays a pivotal role in eliminating manual errors, thereby ensuring a higher level of accuracy in various loan management tasks. The generation of precise and comprehensive journal entries contributes to enhanced financial record accuracy, providing a transparent view of transactions associated with loans. The incorporation of robust validation checks is instrumental in minimizing the risk associated with granting loans to ineligible customers or accepting inadequate collateral. This, in turn, reduces the potential for defaults and mitigates the overall risk of loan losses. Additionally, the comprehensive reporting functionalities offered by the automated system provide valuable insights into the performance of the loan portfolio, highlighting overdue loans and profitability. This wealth of information empowers management to make informed, data-driven decisions for more effective and strategic financial management.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={BC} alt="DNAV" title="DNAV"/>
                            </div>
                            <p>Dynamics 365 Business Central</p>
                        </div>
                    </Col>
                    
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default logisticsanddistribution;
