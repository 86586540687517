import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import WCFServices from "../../../assets/images/casestudies/wcf-services.png";
import Net from "../../../assets/images/casestudies/net.png";
import mvc from "../../../assets/images/casestudies/mvc.png";
import bootstrap from "../../../assets/images/casestudies/bootstrap.jpg";
import IIS from "../../../assets/images/casestudies/iis.png";
import htmlcss from "../../../assets/images/casestudies/htmlcss.png";
import sql2012 from "../../../assets/images/casestudies/sql2012.jpg";
import enterprisedevelopmentbanner from "../../../assets/images/banner/enterprise-development-and-online-training-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function enterprisedevelopment() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Enterprise Development and Online Training' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Enterprise Development and Online Training | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="In the United States, a prominent supplier of online training resources focusing on the training and development of enterprises workforce. Using their content distribution system, our customer provides interactive training content to users across numerous corporations and campuses."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/enterprise-development-and-online-training" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Enterprise Development and Online Training</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={enterprisedevelopmentbanner} alt="Enterprise Development" title="Enterprise Development" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>In the United States, a prominent supplier of online training resources focusing on the training and development of enterprises workforce. Using their content distribution system, our customer provides interactive training content to users across numerous corporations and campuses.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>It serves clients in a variety of industries, including higher education, automotive and transportation services, retail and consumer products, financial services, legal and accounting services, electronics and telecommunications, federal, state, and municipal governments, sports, entertainment, food, beverage, and hospitality.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>the upkeep of many programmes, Meet strict performance and scalability needs without requiring large changes to existing databases that can support more than 10,000 concurrent users. There is a heavy reliance on hardware capacity to support increased user loads. <br/> The ability to instantly modify the appearance and feel of content via customisable skins All old skins are being migrated using the new skin builder. The ability to support a frameless design, The current database was around 100 GB in size, and the application should be compatible with both the old and new interfaces. </p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>With a performance-centric design, it is possible to accommodate up to 15000 concurrent users. Lowering additional hardware costs by expanding software capabilities to support increased user loads. Delivered 150+ skins to boost productivity by easily altering the appearance and feel of content via customisable Skins. Using Single Sign-on, we simplified the integration process with Campuses. Best practises in adaptation and localization were used to accommodate multilingual courses. Reduced the time required to handle course content, assessments, and surveys. Reduced the technical expertise necessary to create SCORM Courses. Through the application of 508 / WCAGAA accessibility standards, we were able to provide a better user experience. Vulnerability was avoided by adhering to tight security measures.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={Net} alt="Net" title="Net"/>
                            </div>
                            <p>C# .NET 4.5</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={mvc} alt="mvc" title="mvc"/>
                            </div>
                            <p>ASP. NET MVC5</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={bootstrap } alt="bootstrap " title="bootstrap "/>
                            </div>
                            <p>	jQuery & Bootstrap</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sql2012 } alt="sql2012 " title="sql2012 "/>
                            </div>
                            <p>SQL server</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={WCFServices} alt="WCF-Services" title="WCF-Services"/>
                            </div>
                            <p>	WCF Services </p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={IIS} alt="IIS" title="IIS"/>
                            </div>
                            <p>IIS7.5/8.0</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={htmlcss} alt="htmlcss" title="htmlcss"/>
                            </div>
                            <p>HTML5/CSS3</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default enterprisedevelopment;
