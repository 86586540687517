import "../life-synoverge/life-synoverge.scss"

const data =[
    {
      id:1,
      image: require("../../assets/images/gallery/fun-friday/1.jpg"),
      title:"Antakshari",
      category: 'Fun Friday',
       
    },
    {
        id:2,
        image: require("../../assets/images/gallery/fun-friday/2.jpg"),
        title:"Antakshari",
        category: 'Fun Friday',
       
    },
    {
        id:3,
        image: require("../../assets/images/gallery/fun-friday/3.jpg"),
        title:"Antakshari",
        category: 'Fun Friday',
    },
    {
      id:4,
      image: require("../../assets/images/gallery/fun-friday/4.jpg"),
      title:"Bingo",
      category: 'Fun Friday',
  },
    {
        id:5,
        image: require("../../assets/images/gallery/sports/1.jpg"),
        title:"Sports",
        category: 'Sports',
       
    },
    {
        id:6,
        image: require("../../assets/images/gallery/sports/2.jpg"),
        title:"Sports",
        category: 'Sports',
       
    },
    {
        id:7,
        image: require("../../assets/images/gallery/sports/3.jpg"),
        title:"Sports",
        category: 'Sports',
       
    },
    {
        id:8,
        image: require("../../assets/images/gallery/sports/4.jpg"),
        title:"Sports",
        category: 'Sports',
       
    },
    {
        id:9,
        image: require("../../assets/images/gallery/sports/5.jpg"),
        title:"Sports",
        category: 'Sports',
       
    },
    {
        id:10,
        image: require("../../assets/images/gallery/sports/6.jpg"),
        title:"Sports",
        category: 'Sports',
       
    },
    {
        id:11,
        image: require("../../assets/images/gallery/sports/7.jpg"),
        title:"Sports",
        category: 'Sports',
       
    },
    {
      id:12,
      image: require("../../assets/images/gallery/newyear/1.jpg"),
      title:"New Year",
      category: 'New Year',
     
  },
    {
        id:13,
        image: require("../../assets/images/gallery/newyear/3.jpg"),
      title:"New Year",
      category: 'New Year',
       
    },
    {
        id:14,
        image: require("../../assets/images/gallery/newyear/4.jpg"),
      title:"New Year",
      category: 'New Year',
       
    },
    {
        id:15,
        image: require("../../assets/images/gallery/newyear/5.jpg"),
      title:"New Year",
      category: 'New Year',
       
    },
    
    {
      id:16,
      image: require("../../assets/images/gallery/fun-friday/5.jpg"),
      title:"Bingo",
      category: 'Fun Friday',
  },
{
    id:17,
    image: require("../../assets/images/gallery/celebration/1.jpg"),
    title:"Celebration",
    category: 'Celebration',
},
{
id:18,
    image: require("../../assets/images/gallery/celebration/2.jpg"),
    title:"Celebration",
    category: 'Celebration',
},

{
id:19,
    image: require("../../assets/images/gallery/celebration/4.jpg"),
    title:"Celebration",
    category: 'Celebration',
},
{
id:20,
    image: require("../../assets/images/gallery/celebration/5.jpg"),
    title:"Celebration",
    category: 'Celebration',
},
{
id:21,
    image: require("../../assets/images/gallery/celebration/6.jpg"),
    title:"Celebration",
    category: 'Celebration',
},
{
id:22,
    image: require("../../assets/images/gallery/celebration/7.jpg"),
    title:"Celebration",
    category: 'Celebration',
},
{
id:23,
    image: require("../../assets/images/gallery/celebration/8.jpg"),
    title:"Celebration",
    category: 'Celebration',
},
{
id:24,
    image: require("../../assets/images/gallery/celebration/9.jpg"),
    title:"Celebration",
    category: 'Celebration',
},
{
id:25,
    image: require("../../assets/images/gallery/celebration/10.jpg"),
    title:"Celebration",
    category: 'Celebration',
},
{
  id:26,
      image: require("../../assets/images/gallery/celebration/3.jpg"),
      title:"Celebration",
      category: 'Celebration',
  },
  {
    id:27,
        image: require("../../assets/images/gallery/celebration/11.jpg"),
        title:"Celebration",
        category: 'Celebration',
    },
    {
      id:28,
          image: require("../../assets/images/gallery/celebration/12.jpg"),
          title:"Celebration",
          category: 'Celebration',
      },
      {
        id:29,
            image: require("../../assets/images/gallery/celebration/13.jpg"),
            title:"Celebration",
            category: 'Celebration',
        },
        {
          id:30,
              image: require("../../assets/images/gallery/celebration/14.jpg"),
              title:"Celebration",
              category: 'Celebration',
          },
{
id:31,
    image: require("../../assets/images/gallery/mdp/1.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:32,
    image: require("../../assets/images/gallery/mdp/2.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:33,
    image: require("../../assets/images/gallery/mdp/3.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:34,
    image: require("../../assets/images/gallery/mdp/4.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:35,
    image: require("../../assets/images/gallery/mdp/5.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:36,
    image: require("../../assets/images/gallery/mdp/6.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:37,
    image: require("../../assets/images/gallery/mdp/7.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:38,
    image: require("../../assets/images/gallery/mdp/8.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:39,
    image: require("../../assets/images/gallery/mdp/9.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:40,
    image: require("../../assets/images/gallery/mdp/10.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:41,
    image: require("../../assets/images/gallery/mdp/11.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:42,
    image: require("../../assets/images/gallery/mdp/12.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:43,
    image: require("../../assets/images/gallery/mdp/13.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:44,
    image: require("../../assets/images/gallery/mdp/14.jpg"),
    title:"MDP",
    category: 'MDP',
},
{
id:45,
    image: require("../../assets/images/gallery/celebration/15.jpg"),
    title:"Celebration",
    category: 'Celebration',
},
{
id:46,
    image: require("../../assets/images/gallery/celebration/16.jpg"),
    title:"Celebration",
    category: 'Celebration',
},
{
id:47,
    image: require("../../assets/images/gallery/celebration/17.jpg"),
    title:"Celebration",
    category: 'Celebration',
},
{
  id:48,
      image: require("../../assets/images/gallery/celebration/18.jpg"),
      title:"Celebration",
      category: 'Celebration',
  },
  {
id:49,
    image: require("../../assets/images/gallery/celebration/19.jpg"),
    title:"Celebration",
    category: 'Celebration',
},
{
  id:50,
      image: require("../../assets/images/gallery/celebration/20.jpg"),
      title:"Celebration",
      category: 'Celebration',
  },
  {
    id:51,
        image: require("../../assets/images/gallery/celebration/21.jpg"),
        title:"Celebration",
        category: 'Celebration',
    },
    {
      id:52,
          image: require("../../assets/images/gallery/celebration/22.jpg"),
          title:"Celebration",
          category: 'Celebration',
      },
      {
        id:53,
            image: require("../../assets/images/gallery/sports/8.jpg"),
            title:"Sports",
            category: 'Sports',
        },
      
     ]
  
  export default data;