import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import Net from "../../../assets/images/casestudies/net.png";
import bootstrap from "../../../assets/images/casestudies/bootstrap.jpg";
import sqlserver from "../../../assets/images/casestudies/sqlserver.png";
import windowazure from "../../../assets/images/casestudies/windowazure.png";
import htmlcss from "../../../assets/images/casestudies/htmlcss.png";
import datacollectionplatformbanner from "../../../assets/images/banner/data-collection-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";



function datacollectionplatform() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Data Collection Platform' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Data Collection Platform | Synoverge 
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="A big European software product and services firm that specialized in the finance, health care, and insurance industries. The client was searching for a partner with experience in Cloud Computing Technologies, particularly Microsoft Azure, to create the platform and deliver value added services such as creating the SaaS Subscription Model and Payment Models."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/data-collection-platform" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Data Collection Platform</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={datacollectionplatformbanner} alt="Data Collection Platform" title="Data Collection Platform" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>A big European software product and services firm that specialized in the finance, health care, and insurance industries. The client was searching for a partner with experience in Cloud Computing Technologies, particularly Microsoft Azure, to create the platform and deliver value added services such as creating the SaaS Subscription Model and Payment Models.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Client needs to construct a dynamic form to take diverse information from different sorts of users and collect data for analysis, however no available solution/platform can match this demand. The ability to create forms with many layout choices is a specific necessity. Multi-tenant option with theme options, capture parameter analysis, and dynamic report builder are all available.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>There are few challenges like Scalable design for managing large workloads using Microsoft Azure capabilities and.NET Technologies that are highly flexible and secure to meet tenants functional and non-functional needs. The Drag-and-Drop User Interface is a highly-institutive user interface for online and handheld devices that allows end users to be very productive when developing or filling out forms and tenets can choose their preferred authentication type as well as their subscription model.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The application's outcome is the ability to embed Forms in the client's website/blogs, generate analytical reports, and export data in a variety of formats. Report-writing assistance, Web Services allow the deployment topology to be flexible such that Web Services and MVC Web Application may be deployed on two separate servers under high load, and integrated various analytics tools for monitoring and tracking traffic on the site.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={Net} alt="Dot-Net" title="Dot-Net"/>
                            </div>
                            <p>C# .NET 4.5</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={bootstrap} alt="bootstrap" title="bootstrap"/>
                            </div>
                            <p>jQuery & Bootstrap</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={sqlserver} alt="sqlserver" title="sqlserver"/>
                            </div>
                            <p>SQL Server</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={windowazure} alt="windowazure" title="windowazure"/>
                            </div>
                            <p>WCF, Azure</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={htmlcss} alt="htmlcss" title="htmlcss"/>
                            </div>
                            <p>HTML5 / CSS3</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default datacollectionplatform;
