import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import iosobjectivec from "../../../assets/images/casestudies/iosobjectivec.png";
import androidjava from "../../../assets/images/casestudies/androidjava.jpg";
import Net from "../../../assets/images/casestudies/net.png";
import mysqlserver from "../../../assets/images/casestudies/mysqlserver.png";
import mobileecommercestorebanner from "../../../assets/images/banner/mobile-ecommerce-store-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";





function mobileecommercestore() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Mobile Ecommerce Store' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Mobile Ecommerce Store | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Customer with a base in France is a global market leader in plant-based ingredients. Their goal is future difficulties by utilising the power of nature and providing the highest quality ingredients for the Food, Nutrition, and Health industries."
        />
        <link rel="canonical" href="https://www.synoverge.com/case-studies/mobile-ecommerce-store" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Mobile Ecommerce Store</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={mobileecommercestorebanner} alt="Mobile Ecommerce Store" title="Mobile Ecommerce Store" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Customer with a base in France is a global market leader in plant-based ingredients. Their goal is future difficulties by utilising the power of nature and providing the highest quality ingredients for the Food, Nutrition, and Health industries.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>They wanted to create a mobile application that would allow their clients to browse through different natural goods, learn about them, and buy them, as well as the opportunity to order in quantity and have it delivered in slots.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Future challenges by releasing Nature's full potential and providing the highest-quality ingredients for the Food, Nutrition, and Health markets. Choose the Right Technology, Focus on App Security, Add the Right Features, and Payment Gateway Integration are the most common problems.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>We have created a mobile application for both the iOS and Android platforms that allows you to promote your items. Moreover Customers are notified on a regular basis about availability, pricing changes, and other information.</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={iosobjectivec} alt="iosobjectivec" title="iosobjectivec"/>
                            </div>
                            <p>	iOS- Objective-C</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={androidjava} alt="androidjava" title="androidjava"/>
                            </div>
                            <p>Android - Java</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={Net} alt="net" title="net"/>
                            </div>
                            <p>.Net</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={mysqlserver} alt="mysqlserver" title="mysqlserver"/>
                            </div>
                            <p>MySQL Server</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default mobileecommercestore;
