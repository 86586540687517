import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { AxiosResponse } from "axios";
import indiaflag from "../../assets/images/india-flag.png";
import japanflag from "../../assets/images/japan-flag.png";
import contact from "../../assets/images/contact.png";
import { Controller, useForm } from "react-hook-form";
import { postContactDetails } from "../../utils/api";
import { axiosPost } from "../../utils/helper";
import { toast } from "react-toastify";
import contactusbanner from "../../assets/images/banner/contact-banner.jpg";
import "./contactus.scss";
import CustomBreadcrumb from "../BreadCrumbs/CustomebreadCrumb";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "../home/home.scss";
import WhatOurClientsSay from "../whatOurClientsSay/whatOurClientsSay";

function Contactus() {
  const [isVerified, setIsVerified] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    control,
    clearErrors,
  } = useForm({
    mode: "all",
  });
  const [files, setFiles] = useState<File[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>("");
  const showRecaptcha =
    process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === "qa" ? false : true;

    useEffect(() => {
    if (!showRecaptcha) {
      setIsVerified(true);
    }
  }, []);

  const handleVerify = (response: string | null) => {
    // 'response' will be null if the user cancels the reCAPTCHA
    if (response) {
      setIsVerified(true);
    }
  };

  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    try {
      await postContactData(postContactDetails, { ...data, files });
      reset();
      navigate("/thank-you");
    } catch (error) {
      console.error("Error posting");
    }
  };

  async function postContactData(reqUrl: string, reqBody: string) {
    try {
      const response = (await axiosPost(reqUrl, reqBody)) as AxiosResponse;
      if (response.status === 200) {
        toast.success("Contact-Us Form submitted successfully.", {});
      }
      return response;
    } catch (error: any) {
      toast.error("Failed to submit the Contact-Us Form.", {});
      throw error;
    }
  }
  const breadcrumbItems = [
    { label: "Home", link: "/" },
    { label: "About Synoverge", link: "/our-story" },
    { label: "Contact Us" },
  ];

  const allowedFileTypes = [
    "application/msword", // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/vnd.ms-excel", // .xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "application/vnd.ms-powerpoint", // .ppt
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    "application/vnd.ms-powerpoint.presentation", // .pps
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow", // .ppsx
    "application/vnd.oasis.opendocument.presentation", // .odp
    "image/jpeg", // .jpeg
    "image/jpg", // .jpg
    "image/png", // .png
    "image/vnd.adobe.photoshop", // .psd
    "image/webp", // .webp
    "image/svg+xml", // .svg
    "audio/mpeg", // .mp3
    "video/mp4", // .mp4
    "video/webm", // .webm
    "application/vnd.oasis.opendocument.text", // .odt
    "application/vnd.oasis.opendocument.spreadsheet", // .ods
    "application/pdf", // .pdf
    "application/rtf", // .rtf
    "text/plain", // .txt
    "text/csv", // .csv
    "text/plain", // .log
    "application/zip", // .zip
    "application/x-tar", // .tar
    "application/x-tar-gz", // .tar-gz
  ];

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const selectedFiles = event.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const filesArray = Array.from(selectedFiles).map((file: File) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            if (reader.result) {
              const base64String = reader.result.toString().split(",")[1];
              resolve({
                filename: file.name,
                filetype: file.type,
                size: file.size,
                data: base64String,
              });
            } else {
              reject("File reading failed");
            }
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        });
      });

      Promise.all(filesArray)
        .then((files: any) => {
          setFiles(files);
        })
        .catch((error) => {
          console.error("Error reading files", error);
        });
    }
  };

  // Sync phone number with react-hook-form
  useEffect(() => {
    setValue("PhoneNumber", phoneNumber);
  }, [phoneNumber, setValue]);

  return (
    <Container fluid className="px-0">
      <Helmet>
        <title>Contact Synoverge | Get in Touch Now | Synoverge</title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="
          We are here to support the success of your company. Reach out to Synoverge. Get in touch for professional IT assistance, questions, and partnerships."
        />
        <link rel="canonical" href="https://www.synoverge.com/contact-us" />
      </Helmet>
      <div className="contact-us">
        <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Contact Us</h1>
                  {/* <h1>Transforming Data into Intelligence</h1> */}
                  <p>
                    Improve decision making through business insights with our
                    analytics roadmap
                  </p>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img
                    src={contactusbanner}
                    alt="Contactus"
                    title="Contactus"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="module">
          <Container>
            <CustomBreadcrumb items={breadcrumbItems} />
            <Row>
              <h3>Get In Touch</h3>
              <Col xs={12} md={3}>
                <div className="contact-footer">
                  <div>
                    <img
                      src={indiaflag}
                      alt="india"
                      title="india"
                      className="flagimg"
                    />
                  </div>
                  <h4>Synoverge India</h4>
                  <p>
                    The Summit, 3 Sunrise Park,
                    <br />
                    Sunrise Park Road, Bodakdev,
                    <br />
                    Ahmedabad 380054, Gujarat - India.
                  </p>
                  <p>
                    <strong>Tel.:</strong>{" "}
                    <Link to="tel:+917949062116">+91 79 49062116</Link>/
                    <Link to="tel:+917949062117">7</Link>
                  </p>
                  <p>
                    <strong>US Number:</strong> 201-565-9282
                  </p>
                  <p>
                    <strong>Skype:</strong>{" "}
                    <Link to="skype:synoverge">synoverge</Link>
                  </p>
                  <p>
                    <strong>Email: </strong>
                    <Link to="mailto:info@synoverge.com">
                      info@synoverge.com
                    </Link>
                  </p>
                  <div className="mt-40">
                    <iframe
                      title="Map"
                      src={
                        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.41632148027!2d72.5275071153543!3d23.04519372126436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84b05399196f%3A0xee01b0990d6ca12c!2sSynoverge%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1583398895233!5m2!1sen!2sin"
                      }
                      height={305}
                      width={"100%"}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={3}>
                <div className="contact-footer">
                  <div>
                    <img
                      src={japanflag}
                      alt="japan"
                      title="japan"
                      className="flagimg"
                    />
                  </div>
                  <h4>Synoverge Japan Co., Ltd.</h4>
                  <p>
                    7-5-14, Funabori, Edogawa-Ku,
                    <br />
                    Tokyo, Japan 〒134-0091
                  </p>
                  <p>
                    <strong>Tel.:</strong>{" "}
                    <Link to="tel:+81(3) 68770905">+81 (3) 6877-0905</Link>
                  </p>
                  <p>
                    <strong>Email:</strong>{" "}
                    <Link to="mailto:japan@synoverge.com">
                      japan@synoverge.com
                    </Link>
                  </p>
                  <div className="mt-132">
                    <iframe
                      title="Map"
                      src={
                        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.9281450695153!2d139.86830751510573!3d35.678771237714564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601887c69ba2b2f5%3A0x5b32029eb22a04b4!2s7-ch%C5%8Dme-5-14%20Funabori%2C%20Edogawa%20City%2C%20Tokyo%20134-0091%2C%20Japan!5e0!3m2!1sen!2sin!4v1650538739264!5m2!1sen!2sin"
                      }
                      height={305}
                      width={"100%"}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="contact-footer">
                  <div>
                    <img
                      src={contact}
                      alt="contact"
                      title="contact"
                      className="flagimg"
                    />
                  </div>
                  <h4>Let's Discuss Your Requirements</h4>
                  <Form onSubmit={handleSubmit(onSubmit)} className="form">
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="First Name*"
                            {...register("FirstName", {
                              required: "First Name is required",
                            })}
                          />
                          {errors.FirstName && (
                            <p style={{ color: "red" }}>
                              {errors.FirstName.message as string}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Last Name*"
                            {...register("LastName", {
                              required: "Last Name is required",
                            })}
                          />
                          {errors.LastName && (
                            <p style={{ color: "red" }}>
                              {errors.LastName.message as string}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Business Email*"
                            {...register("Email", {
                              required: "Business Email is required",
                              pattern: {
                                value: /^\S+@\S+$/i,
                                message: "Invalid email format",
                              },
                            })}
                          />
                          {errors.Email && (
                            <p style={{ color: "red" }}>
                              {errors.Email.message as string}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Company Name"
                            {...register("Company", {
                              //required: "Company Name is required",
                            })}
                          />
                          {/* {errors.Company && (
                            <p style={{ color: "red" }}>
                              {errors.Company.message as string}
                            </p>
                          )} */}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Controller
                            control={control}
                            name="PhoneNumber"
                            rules={{
                              // This validation will only apply if the phone number is entered
                              validate: (value: string) => {
                                if (value && !isValidPhoneNumber(value)) {
                                  return "Invalid phone number"; // Show error if the number is invalid
                                }
                                return true; // Allow valid or empty input
                              },
                            }}
                            render={({ field }) => (
                              <div>
                                <PhoneInput
                                  international
                                  defaultCountry="IN"
                                  value={field.value}
                                  onChange={(value) => {
                                    setPhoneNumber(value); // Update the state
                                    field.onChange(value); // Update react-hook-form
                                  }}
                                  placeholder="Phone Number"
                                  className=" phone-input"
                                />
                              </div>
                            )}
                          />
                          {errors.PhoneNumber && (
                            <p style={{ color: "red" }}>
                              {errors.PhoneNumber.message as string}
                            </p>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3 upload-resume">
                          <Form.Control
                            type="file"
                            multiple
                            {...register("Documents", {
                              validate: (files) => {
                                if (!files) return true; // If no files are selected, it's valid

                                // Validate file count (max 5)
                                if (files.length > 5) {
                                  return "You can upload up to 5 files only.";
                                }

                                // Validate total file size (max 20MB)
                                const totalSize: number = (() => {
                                  let total = 0;
                                  for (const file of files) {
                                    total += file.size;
                                  }
                                  return total;
                                })();

                                if (totalSize > 20 * 1024 * 1024) {
                                  // 20MB
                                  return "Total file size cannot exceed 20MB.";
                                }

                                // Validate individual file size (max 10MB)
                                for (let file of files) {
                                  if (file.size > 10 * 1024 * 1024) {
                                    // 10MB
                                    return `The file ${file.name} exceeds the 10MB limit.`;
                                  }
                                }

                                // Validate file types
                                for (let file of files) {
                                  if (!allowedFileTypes.includes(file.type)) {
                                    return `The file type of ${file.name} is not allowed.`;
                                  }
                                }

                                return true; // All validations passed
                              },
                            })}
                            onChange={handleChangeFile}
                          />
                          {errors.Documents && (
                            <p style={{ color: "red" }}>
                              {errors.Documents.message as string}
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="contact-comments">
                          <Form.Control
                            as="textarea"
                            placeholder="Kindly describe your needs*"
                            rows={5}
                            {...register("Comments", {
                              required: "This field is required",
                            })}
                          />
                          {errors.Comments && (
                            <p style={{ color: "red" }}>
                              {errors.Comments.message as string}
                            </p>
                          )}
                        </Form.Group>
                      </Col>

                      {showRecaptcha && (
                        <Col lg={12}>
                          <Form.Group className="contact-captcha">
                            <ReCAPTCHA
                              sitekey="6LetrCYpAAAAAPk24gcHCpl16hAYzC8_87MARhWv"
                              onChange={handleVerify}
                            />
                          </Form.Group>
                        </Col>
                      )}
                      
                      <Col lg={12}>
                        <Form.Group className="mb-2 height-50">
                          <Controller
                            name="WantToSignNDA"
                            control={control}
                            render={({ field }) => (
                              <Form.Check>
                                <Form.Check.Input type="checkbox" {...field} />
                                <Form.Check.Label>
                                  {" "}
                                  I want to sign an NDA.
                                </Form.Check.Label>
                              </Form.Check>
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="height-50">
                          <Controller
                            name="TermsAndCondition"
                            control={control}
                            rules={{
                              required:
                                "You must accept the terms and conditions",
                            }} // Add validation rule here
                            render={({ field }) => (
                              <Form.Check>
                                <Form.Check.Input type="checkbox" {...field} />
                                <Form.Check.Label>
                                  {" "}
                                  By submitting this form, I confirm that I
                                  accept the Synoverge{" "}
                                  <Link to="../privacy-policy" target="_blank">
                                    Privacy Policy
                                  </Link>
                                  .{" "}
                                </Form.Check.Label>
                              </Form.Check>
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mt-3">
                          <Button
                            type="submit"
                            value="Submit"
                            className="submit-btn submit"
                            disabled={!(isVerified && isValid)}
                          >
                            Submit
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="landing-page">
          <WhatOurClientsSay />
        </div>
      </div>
    </Container>
  );
}

export default Contactus;
