import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import msdynamics from "../../../assets/images/casestudies/msdynamics.png";
import SQL2014 from "../../../assets/images/casestudies/sql-2014.jpg";
import mscrm from "../../../assets/images/casestudies/mscrm.jpg";
import managementreport from "../../../assets/images/casestudies/management-report.png";
import nongovernmentalbanner from "../../../assets/images/banner/non-governmental-organizations-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";




function nongovernmentalorganizations() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Non-governmental organizations' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        Non-governmental organizations | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Customer is a well-known Indian NGO. Maintaining Funder Reports in Excel and managing financials were key issues for the customer. The customer needed to automate specific activities within the Sales/CRM department and Finance operations to reduce manual interventions and delays in system upgrades."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/non-governmental-organizations" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>Non-governmental organizations</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={nongovernmentalbanner} alt="non-governmental-organizations" title="non-governmental-organizations" />
                </div>                
              </Col>
            </Row>
          </Container>
      </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Customer is a well-known Indian NGO. Maintaining Funder Reports in Excel and managing financials were key issues for the customer. The customer needed to automate specific activities within the Sales/CRM department and Finance operations to reduce manual interventions and delays in system upgrades.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The project is an electronic guarantee bond handling framework that allows clients, merchants/specialists, and carriers to efficiently manage their guarantee bond requirements. They have a wealth of knowledge about clients and businesses that they may use to improve their present CRM operations.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>The finance staff at HO had a huge challenge in consolidating financials and assisting statutory auditors. To decrease / eliminate manual interventions and delays in system upgrades, the customer required to automate certain of the procedures within the Sales/CRM department and Finance operations.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>With HO Control, an integrated financials and procurement system is available. Funder Report & Standardized Project Budgets Reduced human mistakes and reporting delays by integrating with HRMS and improving resource allocation to projects. Financial consolidation and reporting run well – satisfied statutory auditors and funders .</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={msdynamics} alt="msdynamics" title="msdynamics"/>
                            </div>
                            <p>MS Dynamics AX 2012 R3</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={SQL2014} alt="SQL-2014" title="SQL-2014"/>
                            </div>
                            <p>	SQL SERVER 2012 R2</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={mscrm} alt="mscrm" title="mscrm"/>
                            </div>
                            <p>MS CRM 2016</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3} className="d-inline-flex">
                        <div className="engaged-col-bg">
                            <div>
                                <img src={managementreport} alt="management-report" title="management-report"/>
                            </div>
                            <p>Management Reporter</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default nongovernmentalorganizations;
