import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iconclient from "../../../assets/images/animated-icons/social-care.gif";
import iconoverview from "../../../assets/images/animated-icons/research.gif";
import iconchallenges from "../../../assets/images/animated-icons/implementation.gif";
import iconresults from "../../../assets/images/animated-icons/wedding.gif";
import Net from "../../../assets/images/casestudies/net.png";
import angular from "../../../assets/images/casestudies/angular6.jpg";
import bootstrap from "../../../assets/images/casestudies/bootstrap.jpg";
import SQL from "../../../assets/images/casestudies/sql-2014.jpg";
import windowazure from "../../../assets/images/casestudies/windowazure.png";
import htmlcss from "../../../assets/images/casestudies/htmlcss.png";
import surepathdigitalbanner from "../../../assets/images/banner/surepathdigital-banner.jpg";
import CustomBreadcrumb from '../.././BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";



function surepathdigital() {
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Case Studies',link:'/case-studies'},
        { label: 'Surepath Digital' },
    
      ];
  return (
      <div className="insurance">
        <Helmet>
        <title>
        SurepathDigital | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="Our client offers complete consulting and information technology services to the insurance and surety industries. Substantial expertise in the surety market, insurance corporate finance, and insurance-related system development."
        />
        <link rel="canonical"href="https://www.synoverge.com/case-studies/surepathdigital" />
      </Helmet>
      <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>SurepathDigital</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={surepathdigitalbanner} alt="SurepathDigital" title="SurepathDigital" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <CustomBreadcrumb items={breadcrumbItems} />
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconclient} alt="icon-client" title="icon-client" />
                    </div>
                    <h3>Brief about the Client</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Our client offers complete consulting and information technology services to the insurance and surety industries. Substantial expertise in the surety market, insurance corporate finance, and insurance-related system development.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconoverview} alt="icon-overview" title="icon-overview" />
                    </div>
                    <h3>Business Overview</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Our client has extensive experience in the surety market, insurance corporate finance, and the creation of insurance-related systems. They digitally integrate the surety world by facilitating seamless communication among and between obliges, principals, and sureties. Their network facilitates all types of surety bonding.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <div>
                        <img src={iconchallenges} alt="icon-challenges" title="icon-challenges" />
                    </div>
                    <h3>Business Challenges</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>It does not allow for customization or adjustment depending on business requirements. Inadequate reporting capabilities and access to information stored in the system. The inability to respond and address problems in a timely manner is hampered by a lack of knowledge. There is a heavy reliance on hardware capacity to support increased user loads.</p>
                    </div>
                </Col>
            </Row>
            </div>
            <div className="graybg-casestudy">
            <Row>
                <Col xs={12} md={4}>
                    <p>
                        <img src={iconresults} alt="icon-results" title="icon-results" />
                    </p>
                    <h3>Outcome and Benefits</h3>
                </Col>
                <Col xs={12} md={8}>
                    <div>
                        <p>Productivity is boosted by optimising the code and database, which increases the pace of work. Opportunity for improvement by improving the present product and expanding its capabilities by introducing new features. Improved business flexibility, agility, and openness to change. improved decision-making capacity based on reporting and BI skills Customer satisfaction with services has increased. Improved processes and execution</p>
                    </div>
                </Col>
            </Row>
            </div>
        </Container>
        <Container>
        <div className="engaged-col">
                <Row>
                    <Col md={12}>
                        <h3>Tools and Technologies</h3>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={Net} alt="Dot-Net" title="Dot-Net"/>
                            </div>
                            <p>C# .NET4.5</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={angular} alt="angular6" title="angular6"/>
                            </div>
                            <p>Angular6</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={bootstrap} alt="bootstrap" title="bootstrap"/>
                            </div>
                            <p>Bootstrap</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={SQL} alt="SQL" title="SQL"/>
                            </div>
                            <p>	SQL Server, Web API</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={windowazure} alt="windowazure" title="windowazure"/>
                            </div>
                            <p>Windows Azure</p>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="engaged-col-bg">
                            <div>
                                <img src={htmlcss} alt="htmlcss" title="htmlcss"/>
                            </div>
                            <p>HTML5/CSS3</p>
                        </div>
                    </Col>
                </Row>
                </div>
            </Container>
      </div>
  );
}

export default surepathdigital;
