import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../pages/pages.scss";
import DataReconciliation from "../../assets/images/blog/data-reconciliation-banner.jpg";
import BooksandAccounts from "../../assets/images/blog/books-and-accounts.jpg";
import datareconciliationbanner from "../../assets/images/banner/data-reconciliation-banner.jpg";
import CustomBreadcrumb from '../BreadCrumbs/CustomebreadCrumb';
import { Helmet } from "react-helmet";

function datareconciliation() {
  const breadcrumbItems = [
    { label: 'Home', link: '/' },
    { label: 'Blog', link:'/blog' },
    { label: 'GSTR-2A Data Reconciliation with books of accounts' },
  ];

  return (
    <Container fluid className="px-0">
      <div className="blog-content">
      <Helmet>
        <title>
        GSTR-2A Data Reconciliation with books of accounts | Synoverge
        </title>
        <meta name="robots" content="index,follow"/>
        <meta
          name="description"
          content="GSTR 2A is a purchase-related tax return that is automatically generated for each business by the GST portal. When a seller files his GSTR-1, the information is captured in GSTR 2A. It takes information of goods and/or services which have been purchased in a given month from various seller's GSTR-1."
        />
        <link rel="canonical" href="https://www.synoverge.com/blog/gstr-2a-data-reconciliation-with-books-of-accounts" />
      </Helmet>
        <div className="banner">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="banner-cont">
                  <h1>GSTR-2A Data Reconciliation with books of accounts</h1>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="banner-img">
                  <img src={datareconciliationbanner} alt="ourstory" title="ourstory" />
                </div>                
              </Col>
            </Row>
          </Container>
        </div>
        <div className="module">
          <Container>
          <CustomBreadcrumb items={breadcrumbItems} />
              <Row>
                <Col md={12}>
                    <div className="Job-content">
                        <h3>1. Business Needs</h3>
                        <ul className="icon-list">
                            <li>
                              <strong> What is GSTR 2A</strong>
                              <ul>
                                <li>GSTR 2A is a purchase-related tax return that is automatically generated for each business by the GST portal. When a seller files his GSTR-1, the information is captured in GSTR 2A. It takes information of goods and/or services which have been purchased in a given month from various seller's GSTR-1.</li>
                              </ul>
                            </li>
                        </ul>
                    </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                    <div className="Job-content">
                        <ul>
                          <li>
                            <strong>What to reconcile</strong>
                            <ul>
                              <li>
                              Purchase transactions posted in the system for a month shall be reconciled with the supplier uploaded information in GSTR-2A. This reconciliation is needed because, certain discrepancies may arise between the two due to the following reasons:
                              <ul>
                                <li>Any supplier or suppliers may ignore to upload invoice details in their GSTR-1</li>
                                <li>Supplier entering wrong GSTIN of the buyer</li>
                                <li>Omission of entering any invoice by the buyer</li>
                                <li>Invoice No. & Date, Tax Amounts may be wrongly entered by the buyer in his Accounting System/books of account.</li>
                              </ul>
                              </li>
                              <li>
                                <strong>Matching concept ensures that all the transactions of a particular period have been recorded.</strong>
                              </li>
                            </ul>
                          </li>
                        </ul>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="Job-content">
                        <img src={DataReconciliation} alt="Data-Reconciliation" title="Data-Reconciliation" className="w-100" />
                    </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                    <div className="Job-content">
                        <ul className="icon-list">
                            <li>
                              <strong>Why it is important </strong>
                              <ul>
                                <li> <strong>Amendment in GST Rules:</strong> According to the amended rules which is effective from 9th October, 19 any Input tax credit to be availed <strong>shall not exceed 20% of the eligible credit available</strong> in respect of purchases made by the registered person. </li>
                                <li>Earlier, such credit could be claimed without any restrictions based only on the invoice copy after satisfying other conditions laid down under Section 16 of the CGST Act. As a result of this new restriction, regular matching of input tax credit with details available in Form GSTR-2A have become necessary for taxpayers before claiming input tax credit in monthly GST return.</li>
                                <li> <strong>Cash flow issues: </strong>Now with respect to amendment in above rule, every purchaser needs to validate and check whether the supplier has legitimately paid the GST liability to the Government or not? And if the supplier is fails to do so, purchaser cannot take the credit of the ITC of GST value which he has already paid off to the supplier. And hence this will put stress on the working capital management of the purchaser. </li>
                                <li>Monitor unmatched transaction lines & to follow-up with the supplier to do the required compliances which also leads to the vendor management and vendor rating.</li>
                              </ul>
                            </li>
                        </ul>
                    </div>
                </Col>
              </Row>
          </Container>
        </div>
        <div className="module module-gray">
            <Container>
              <Row>
                <Col md={12}>
                    <div className="Job-content">
                        <h3>2. What our Solution offers?</h3>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <ul className="icon-list">
                        <li>Powerful reconciliation tool</li>
                        <li>100 % GST compliant Microsoft Tool</li>
                        <li>Single click GSTR-2A download</li>
                    </ul>
                </Col>
                <Col xs={12} md={4}>
                    <ul className="icon-list">
                        <li>Single view of the downloaded data</li>
                        <li>Automated Vendor Communication</li>
                        <li>Advance matching with 50+ inbuilt validations</li>
                    </ul>
                </Col>
                <Col xs={12} md={4}>
                    <ul className="icon-list">
                        <li>‘Update Invoice Feature’ to rectify mismatched invoices in NAV</li>
                        <li>Flexible posting of reconciled entries</li>
                    </ul>
                </Col>
              </Row>
            </Container>
        </div>
        <div className="module">
            <Container>
              <Row>
                <Col xs={12} lg={6}>
                    <div className="Job-content">
                        <h3>3. What are the benefits?</h3>
                        <ul className="icon-list">
                          <li> <strong>Working Capital Solution:</strong>  Companies can hold the payment of such invoices if the GST credit is not been reflected in the GSTR-2A. But to get such transactions, companies need the powerful tool which will do the reconciliation with accuracy and in real time basis.</li>
                          <li> <strong>Pre-emptive Solution:</strong>  In case of audits or notices due to invoice mismatches found, it may lead to time consuming reconciliation and could trigger a notice from Government. This powerful tool will take care your GST reconciliation, that it is done monthly and all the data is available at your fingers, should the authorities want them. </li>
                          <li> <strong>Man hour saving:</strong>  Companies where numerous transactions are involved and scattered across geographic locations, then it is observed that there is a specific team in Finance department that works on such kind of reconciliations. This tool does the accurate and instant reconciliation with pre-built validation logic that helps companies to save many man hours of efforts. </li>
                        </ul>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="job-content">
                        <img src={BooksandAccounts} alt="Books-and-Accounts" title="Books-and-Accounts" className="w-100" />
                    </div>
                </Col>
                <Col md={12}>
                    <ul className="icon-list">
                        <li> <strong>Vendor Rating:</strong> Follow up with the Suppliers can be taken up by the company and transactions can be cross checked for the pending GST credit. </li>
                        <li> <strong>Corrections in books of accounts:</strong> There may be the possibility that few transactions have not been entered correctly where HSN / SAC code, GST % etc. are incorrect. By this reconciliation companies can identify such erroneous transactions and correction entries can be made. </li>
                    </ul>
                </Col>
              </Row>
            </Container>
        </div>
        <div className="module module-gray">
            <Container>
              <Row>
                <Col xs={12}>
                    <div className="Job-content">
                        <h3>4. What is the impact?</h3>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <ul className="icon-list mb-0">
                        <li>Claim Maximum ITC</li>
                        <li>Better Vendor rating</li>
                    </ul>
                </Col>
                <Col xs={12} md={6}>
                    <ul className="icon-list mb-0">
                        <li>Preventive Audit control</li>
                        <li>Workflow management</li>
                    </ul>
                </Col>
              </Row>
            </Container>
        </div>
      </div>
    </Container>
  );
}

export default datareconciliation;
